<template>
  <div class="archive-filter" :class="[yearOpen || statusOpen ? 'open' : '']">
    <div class="archive-filter--inner">
      <div class="filter--search">
        <div class="filter--search__icon">
          <img src="@/assets/icons/icon-search.svg" alt="" />
        </div>
        <div class="filter--search__input">
          <input
            v-model="filter.title"
            type="text"
            name="search"
            id="search"
            :placeholder="this.$t('message.components.archiveFilter.search')"
            @click="focusToggle()"
          />
        </div>
      </div>
      <div class="filter--year filter--group">
        <button class="filter--year__label filter--label" @click="toggleYear()">
          <span v-if="!filter.year">{{ $t("message.components.archiveFilter.year") }}</span>
          <span v-else>{{ filter.year }}</span>
          <img
            src="@/assets/icons/icon-chevron-down.svg"
            alt=""
            :class="[yearOpen ? 'open' : '']"
          />
        </button>
        <ul
          class="filter--year__list filter--list"
          :class="[yearOpen ? 'open' : '']"
        >
          <li v-for="(year, index) in years" :key="index">
            <button @click="updateYear(year)">{{ year }}</button>
          </li>
        </ul>
      </div>
      <div class="filter--status filter--group">
        <button
          class="filter--status__label filter--label"
          @click="toggleStatus()"
          :class="[statusOpen ? 'open' : '']"
        >
          <span v-if="!filter.status">{{ $t("message.components.archiveFilter.status") }}</span>
          <span v-else>{{ filter.status }}</span>
          <img
            src="@/assets/icons/icon-chevron-down.svg"
            alt=""
            :class="[statusOpen ? 'open' : '']"
          />
        </button>
        <ul
          class="filter--status__list filter--list"
          :class="[statusOpen ? 'open' : '']"
        >
          <li v-for="(state, index) in status" :key="index">
            <button @click="updateStatus(state)">{{ state }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'ArchiveFilter',
    data() {
        return {
            years: [2016,2017,2018,2019,2020,2021,2022],
            status: ['Construido', 'No construido', 'En progreso', 'Idea', 'Research'],
            yearOpen: false,
            statusOpen: false,

            filter: {
                year: null,
                status: null,
                title: null,
            }
        }
    },
    watch: {
        filter: {
            handler: function () {
                this.$emit('updateFilter', this.filter)
            },
            deep: true
        }
    },
    methods: {
        toggleYear() {
            this.yearOpen = !this.yearOpen
            if(this.statusOpen) {
                this.statusOpen = false
            }
           
        },
        toggleStatus() {
            this.statusOpen = !this.statusOpen
            if(this.yearOpen) {
                this.yearOpen = false
            }
        },
        focusToggle() {
            this.yearOpen = false
            this.statusOpen = false
        },
        updateFilter() {
            this.yearOpen = false
            this.statusOpen = false
        },
        updateYear(year) {
            this.filter.year = year
            this.updateFilter();
        },
        updateStatus(status) {
            this.filter.status = status
            this.updateFilter();
        }
    },
    

}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.archive-filter {
  // position: fixed;
  // z-index: 200;
  // left: 50%;
  // transform: translateX(-50%);
  // top: calc(var(--app-height) * 0.2203898051);
  min-height: calc(var(--base) * 3.375);
  max-height: calc(var(--base) * 3.375);
  width: 100%;
  max-width: 90%;
  @media (min-width: 1024px) {
    min-height: calc(var(--base) * 4.625);
    max-height: calc(var(--base) * 4.625);
    // top: calc(var(--app-height) * 0.1975560081);
    width: auto;
  }
  

  .archive-filter--inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    .filter--group {
      .filter--label {
        font-size: calc(var(--base) * 1.75);
        font-weight: 600;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: calc(var(--base) * 0.5);
        padding: calc(var(--base) * 0.25) calc(var(--base) * 1.25);
        background-color: rgb(var(--bodyBg));
        min-height: calc(var(--base) * 3.375);
        max-height: calc(var(--base) * 3.375);
        @media (min-width: 1024px) {
          font-size: calc(var(--base) * 2.5);
          min-height: calc(var(--base) * 4.625);
          max-height: calc(var(--base) * 4.625);
          padding: calc(var(--base) * 0.25) calc(var(--base) * 2.5);
        }
        img {
          width: calc(var(--base) * 1.75);
          height: calc(var(--base) * 1.75);
          object-fit: contain;
          object-position: center;
          &.open {
            transform: rotate(180deg);
          }
        }
      }
      ul.filter--list {
        height: auto;
        max-height: 0;
        transition: all 0.2s ease-out;
        margin: 0;
        padding: 0;
        overflow: hidden;
        list-style: none;
        border-right: solid 2px rgb(var(--bodyColor));
        border-left: solid 2px rgb(var(--bodyColor));
        border-bottom: solid 2px rgb(var(--bodyColor));
        border-radius: 0 0 calc(var(--base) * 1.25) calc(var(--base) * 1.25);
        background-color: rgb(var(--bodyBg));
        visibility: hidden;
        opacity: 1;

        li {
          width: 100%;
          height: calc(var(--base) * 3.5);
          font-size: calc(var(--base) * 1.75);
          line-height: calc(var(--base) * 3.375);
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: solid 2px rgb(var(--bodyColor));
          @media (min-width: 1024px) {
            font-size: calc(var(--base) * 2);
            line-height: calc(var(--base) * 3.5);
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
        &.open {
          max-height: 100%;
          transition: all 0.5s ease-out;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .filter--search {
      display: flex;
      flex-wrap: nowrap;
      &__icon {
        border-radius: calc(var(--base) * 1.25) 0 0 calc(var(--base) * 1.25);
        border: solid 2px rgb(var(--bodyColor));
        height: 100%;
        width: calc(var(--base) * 6);
        padding: calc(var(--base) * 0.25);
        max-height: calc(var(--base) * 3.375);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(var(--bodyBg));
        @media (min-width: 1024px) {
          max-height: calc(var(--base) * 4.625);
          width: calc(var(--base) * 5);
        }

        //   padding:calc(var(--base) * 0.25);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      &__input {
        width: 100%;
        height: 100%;
        min-height: calc(var(--base) * 3.375);
        max-height: calc(var(--base) * 3.375);
        @media (min-width: 1024px) {
          min-height: calc(var(--base) * 4.625);
          max-height: calc(var(--base) * 4.625);
        }
        input {
          padding: calc(var(--base) * 0.25) calc(var(--base) * 1);
          width: 100%;
          height: 100%;
          font-size: calc(var(--base) * 1.75);
          font-style: italic;
          background-color: rgb(var(--bodyBg));
          border: solid 2px rgb(var(--bodyColor));
          border-left: none;
          border-right: none;
          @media (min-width: 1024px) {
            font-size: calc(var(--base) * 2);
            padding: calc(var(--base) * 0.25) calc(var(--base) * 1.25);
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .filter--year {
      &__label {
        border: solid 2px rgb(var(--bodyColor));
        min-height: calc(var(--base) * 4.625);
        max-height: calc(var(--base) * 4.625);
      }
    }
    .filter--status {
      &__label {
        border-radius: 0 calc(var(--base) * 1.25) calc(var(--base) * 1.25) 0;
        border: solid 2px rgb(var(--bodyColor));
        border-left: none;
        transition: border-radius 0s ease-out 0.2s;
        &.open {
          border-radius: 0 calc(var(--base) * 1.25) 0 0;
          transition: border-radius 0.1s ease-out;
        }
      }
      ul.filter--list {
        width: calc(100% + 2px);
        margin-left: -2px;
      }
    }
  }
}
</style>