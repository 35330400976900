<template>
  <a class="project-card" :style="{ backgroundImage: `url(${img})` }" ref="catColor" @click="buttonLink()">
    <div class="project-card--gradient" />
    <div class="project-card--inner">
      <div class="project-card--publish-state" :class="published ? 'published' : ''" v-if="adminView">
        <span class="project-card--publish-state__text" v-if="published">
          {{ $t("message.admin.projects.published") }}
        </span>
        <span class="project-card--publish-state__text" v-else>
          {{ $t("message.admin.projects.pendant") }}
        </span>
      </div>
      <div class="project-card--edit-btn" v-if="adminView">
        <span class="project-card--edit-btn__icon">
          <img src="@/assets/icons/icon-edit-project.svg" :alt="this.$t('message.admin.projects.edit')" />
        </span>
      </div>
      <div class="project-card--insights">
        <div class="project-card--insights__item stars" v-if="!adminView">
          <span class="icon">
            <img src="@/assets/icons/icon-star.svg" alt="Icono Estrellas" />
          </span>
          <span class="value">{{ stars }}</span>
        </div>
        <div class="project-card--insights__item likes">
          <span class="icon">
            <img src="@/assets/icons/icon-heart.svg" alt="Icono Corazones" />
          </span>
          <span class="value">{{ likes }}</span>
        </div>
        <div class="project-card--insights__item views">
          <span class="icon">
            <img src="@/assets/icons/icon-eye.svg" alt="Icono Visualizaciónes" />
          </span>
          <span class="value">{{ views }}</span>
        </div>
      </div>
      <div class="project-card--info">
        <div class="project-card--info--inner">
          <h3 class="project-card--info--inner__title"
            v-html="$root.$i18n.locale == 'es' ? this.trimString(this.title, 100) : this.trimString(this.titleCat, 100)">
          </h3>
          <div class="project-card--info--inner__author"
            v-html="$root.$i18n.locale == 'es' ? this.author : this.authorCat"></div>
        </div>
        <div class="project-card--info__icon">
          <img :src="isRouteUp ? '/../../' + cat.icon.black : cat.icon.black" alt="" />
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import ProjectService from '@/services/project.services';
export default {
  name: 'ProjectCard',
  props: {
    img: {
      type: String,
      required: false,
      default: require('@/assets/images/card-placeholder-no-image.svg'),
    },
    title: {
      type: String,
      required: true,
    },
    titleCat: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    authorCat: {
      type: String,
      required: true,
    },
    cat: {
      type: Object,
      required: true,
    },
    stars: {
      type: Number,
      required: true,
    },
    likes: {
      type: Number,
      required: true,
    },
    views: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: false,
    },
    adminView: {
      type: Boolean,
      required: false,
      default: false,
    },
    published: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRouteUp: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    changeColor() {
      this.$refs.catColor.style.setProperty('--catColor', this.cat.color);
    },
    buttonLink() {
      if (this.adminView) {
        if (this.$store.getters['auth/hasRole'](['ROLE_CREATOR'])) {
          this.$router.push({ name: 'EditCug', params: { id: this.projectId, slug: ProjectService.getSlugFromTitle(this.title) } });
        } else if (this.$store.getters['auth/hasRole'](['ROLE_ADMIN'])) {
          this.$router.push({ name: 'EditAdminCug', params: { id: this.projectId, slug: ProjectService.getSlugFromTitle(this.titleCat) } });
        }
      } else {
        this.$router.push({ name: 'ProjectSingle', params: { id: this.projectId, slug: ProjectService.getSlugFromTitle(this.titleCat) } });
      }

    },
    //trim string by character length
    trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
  },
  mounted() {
    this.changeColor();
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }

.project-card {
  $gradientColor: var(--catColor);
  width: 100%;
  //   min-width: calc(var(--base) * 44);
  max-width: calc(var(--base) * 44);
  aspect-ratio: 2/3;
  max-height: calc(var(--base) * 54.13);
  height: 100%;
  border-radius: calc(var(--base) * 3.75);
  box-shadow: 4px 9px 14px 0px rgba(0, 0, 0, 0.11);
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;

  & .project-card--gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 0%;
    background: linear-gradient(180deg,
        rgba($gradientColor, 0) 0%,
        rgba($gradientColor, 1) 100%);
    transition: all 0.3s ease;
  }

  & .project-card--inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    padding: 7% 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  & .project-card--publish-state {
    position: absolute;
    top: 7%;
    right: calc(var(--base) * 6 + 5%);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: rgb(var(--bodyColor));
    background-color: rgb(var(--bodyBg));
    border: solid 1px rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 0.625);
    width: auto;
    padding: calc(var(--base) * 0.625) calc(var(--base) * 1.25);

    &.published {
      color: rgb(var(--bodyBg));
      background-color: rgb(var(--bodyColor));
    }

    &__text {
      font-size: calc(var(--base) * 1.25);
      text-transform: uppercase;
    }
  }

  & .project-card--edit-btn {
    position: absolute;
    top: 7%;
    right: calc(5%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(var(--bodyBg));
    border-radius: calc(var(--base) * 1);
    border: solid 1px rgb(var(--bodyColor));
    width: calc(var(--base) * 5);
    height: calc(var(--base) * 5);
  }

  //Insights
  .project-card--insights {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: calc(var(--base) * 1.5);
    font-size: calc(var(--base) * 1.38);
    font-weight: 900;

    & .project-card--insights__item {
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 0.5);
      align-items: center;

      & .icon {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  //Title, author and category icon
  .project-card--info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: calc(var(--base) * 1);

    .project-card--info--inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      width: 100%;
      border-color: green;

      &__title {
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: calc(var(--base) * 1.88);
        line-height: calc(var(--base) * 2);
        font-weight: 700;

        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background: rgb(var(--bodyColor));
          margin-top: calc(var(--base) * 1.5);
        }
      }

      &__title {
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: calc(var(--base) * 1.88);
        line-height: 1.3;
        font-weight: 700;
        position: relative;
        width: 100%;
        display: block;
        padding-bottom: calc(var(--base) * 1);
        margin-bottom: calc(var(--base) * 0.5);

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 1px;
          background: rgb(var(--bodyColor));
          margin-top: calc(var(--base) * 1.5);
        }
      }

      &__author {
        text-align: left;
        font-size: calc(var(--base) * 1.5);
      }
    }

    .project-card--info__icon {
      width: 100%;
      height: 100%;
      max-width: calc(var(--base) * 5);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom center;
      }
    }
  }

  //Hover state
  &:hover {
    box-shadow: 4px 9px 14px 0px rgba(0, 0, 0, 0.11);

    & .project-card--gradient {
      max-height: 100%;
    }

    & .project-card--inner {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
