<template>
  <div class="gradient-scroll"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.gradient-scroll {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  width: 100%;
  height: calc(var(--app-height) * 0.4);
  backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  z-index: 140;
  z-index: 1;
  display: none;

  @media (min-width: 1024px) {
    height: calc(var(--app-height) * 0.24);
    display: block;
  }
}
</style>