<template>
  <div class="page page-user-panel">
    <user-panel-layout active="users">
      <section class="panel-module panel--users" :style="{
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
      }">
        <div class="panel-module--header">
          <h4 class="panel-module--header__title">
            {{ $t("message.admin.panels.users") }}
          </h4>
          <div class="panel-module--header__buttons">buttons</div>
        </div>
        <div class="panel-module--body" :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
        }" :class="[loading ? 'overflow-hidden' : '']">
          <panel-module-loader :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light"
            v-if="loading"></panel-module-loader>
          <div class="panel--users--table" v-if="!loading">
            <!-- start USERS -->
            <div class="messages--user-list">
              <user-status-card v-for="(item, index) in users" :key="index"
                @toggle="selectUser" avatar="" 
                :user="item.username"
                :id="item.id"
                :status="item.enabled"
                :color="selectedColor"
                :message="item.enabled ? 'Aprobado' : 'SIN APROBAR'"
                :role="item.role"
                ></user-status-card>
            </div>
            <!-- <button class="messages--new-conversation--submit" @click="updateUserStatus" :class="!this.selectedId ? 'disabled' : ''">
              {{this.selectedStatus ? 'Bloquear' : 'Aprobar'}}
            </button> -->
            <!-- end USERS -->
          </div>
        </div>
      </section>
      <!-- Final de Tus proyectos -->

    </user-panel-layout>
  </div>
</template>

<script>
import adminService from '@/services/admin.service';
import UserPanelLayout from '@/components/Layouts/UserPanelLayout.vue';
import PanelModuleLoader from '@/components/Loaders/PanelModuleLoader.vue';
import UserStatusCard from '@/components/Cards/UserStatusCard.vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
  name: "UserPanel-projects",
  components: {
    UserPanelLayout,
    PanelModuleLoader,
    UserStatusCard,
  },
  data() {
    return {
      active: 'users',
      isSuperAdmin: this.$store.getters['auth/hasRole'](['ROLE_ADMIN']),
      isCreator: this.$store.getters['auth/hasRole'](['ROLE_CREATOR']),
      isInstitution: this.$store.getters['auth/hasRole'](['ROLE_INSTITUTION']),
      isAdmin: true,
      isOwner: true,
      loading: false,
      selectedId: null,
      selectedStatus: false,
      users: null,
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    selectedColor() {
      if (this.user && this.user.color) {
        return this.user.color;
      }
      return 'default';
    },
    user() {
      return this.$store.state.users.loggedUser;
    },
  },
  methods: {
    selectUser(val, status) {
      this.selectedId = val;
      this.selectedStatus = status;
      this.updateUserStatus();
    },

    updateUserStatus() {
      this.loading = true;
      adminService.updateUserStatus(this.selectedId, !this.selectedStatus)
        .then(() => {
          //refrescar la lista de usuarios y sus props

        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.getUsers();
        });
    },

    toggleAdmin() {
      this.admin = !this.admin;
    },
    returnProjectsByPublished(val) {
      return this.projects.filter((project) => project.published === val);
    },

    getUsers() {
      this.loading = true;
      adminService.getAllUsers()
        .then((response) => {
          this.users = response.content;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

};
</script>

<style lang="scss" scoped>
/* Single Panels */

//Global rules
.panel-module {
  border-radius: calc(var(--base) * 2);
  display: flex;
  flex-direction: column;
  padding: calc(var(--base) * 2);
  transition: all ease-out 0.2s;

  .panel-module--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: calc(var(--base) * 0.125);

    .new-conversation--button {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      column-gap: calc(var(--base) * 0.5);
      color: rgba(var(--bodyColor), 0.5);
      margin-bottom: calc(var(--base) * 1);
    }

    // margin-bottom: calc(var(--base) * 2.625);
    &__title {
      font-size: calc(var(--base) * 2.5);
      line-height: calc(var(--base) * 2.75);
      font-weight: 600;
      max-width: 100%;
      margin: 0;

      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 2.625);
        line-height: calc(var(--base) * 2.875);
        max-width: 90%;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: calc(var(--base) * 1);
      min-height: calc(var(--base) * 4);
    }

    .messages--user-list {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 1);
    }
  }

  .panel-module--body {
    width: 100%;
    height: 100%;
    // background-color: white;
    // border-radius: calc(var(--base) * 1);
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
    overflow-y: scroll;
    position: relative;

    .messages--user-list {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 1);
      padding:calc(var(--base) * 2);
    }

    .messages--new-conversation--submit {
      width: 100%;
      max-width: calc(100% - (var(--base) * 4));
      background-color: rgba(var(--bodyColor), 1);
      color: rgba(var(--bodyBg), 1);
      border-radius: calc(var(--base) * 1.25);
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(var(--base) * 5);
      margin: 0 auto;
      margin-top: calc(var(--base) * 2);
      &.disabled{
        background-color: rgba(var(--bodyColor), 0.5);
        color: rgba(var(--bodyBg), 0.5);
        pointer-events: none;
      }
    }
  }
}

.panel--users {
  grid-column: 1 / span 10;
  grid-row: 1 / span 6;
}
</style>