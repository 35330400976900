<template>
  <div>
    <page-loader
      :color="loaderColor"
      :bgColor="loaderBgColor"
      v-if="!project || loading"
    ></page-loader>
    <!-- button for next project -->
    <div
      class="navigation-buttons"
      v-if="this.$vuetify.breakpoint.width >= 1024"
    >
      <button @click="goToNextProject">
        <img
          class="project--main--image--next"
          src="@/assets/icons/icon-archive-arrow-right.svg"
          alt=""
        />
      </button>
      <button @click="goToPreviousProject">
        <img
          class="project--main--image--prev"
          src="@/assets/icons/icon-archive-arrow-left.svg"
          alt=""
        />
      </button>
    </div>
    <div
      class="page page-project-single"
      :style="{
        backgroundColor: `rgba(${$getProjectCategory(project.type).color}, 1)`,
      }"
      v-if="project"
    >
      <router-link :to="{ name: 'ArchiveCug' }" class="page-title">
        <h1>
          {{ $t("message.general.archive") }}
        </h1>
      </router-link>
      <div class="project--main" id="element-to-print">
        <div class="project--main--image">
          <div ref="swiperProjects" class="project--main--image--swiper swiper">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(image, index) in project.projectMainImages"
                :key="index"
              >
                <img :src="image.imageUrl" :alt="image.imageUrl" />
              </div>
            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>
            <div class="swiper-navigation-wrapper">
              <div class="swiper-button-custom-prev swiper-button">
                <img src="@/assets/icons/icon-chevron-circle-left.svg" alt="" />
              </div>
              <div class="swiper-button-custom-next swiper-button">
                <img
                  src="@/assets/icons/icon-chevron-circle-right.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="project--mention" v-if="project.mention">
            <project-mention-button
              :mention="project.mention"
              :catId="project.type"
            ></project-mention-button>
          </div>
          <div
            class="project--insights-buttons movil"
            v-if="this.loggedIn && this.$vuetify.breakpoint.width < 1024"
          >
            <add-feedback-button
              v-if="isInstitution || isSuperAdmin"
              type="stars"
              :style="{
                backgroundColor: `rgba(${
                  $getProjectCategory(project.type).color
                }, 1)`,
              }"
            ></add-feedback-button>
            <add-feedback-button
              type="likes"
              :style="{
                backgroundColor: `rgba(${
                  $getProjectCategory(project.type).color
                }, 1)`,
              }"
            ></add-feedback-button>
          </div>
        </div>
        <div class="project--main--info">
          <header class="project--main--info--header">
            <div class="project--main--info--header--top">
              <h2
                class="project--main--info--header--top__title"
                v-html="
                  $root.$i18n.locale == 'es'
                    ? this.project.titleCastellano
                    : this.project.titleValencia
                "
              />
              <div class="project--main--info--header--top__date">
                {{ project.year }}
              </div>
            </div>
            <div class="project--main--info--header--bottom">
              <div
                class="project--main--info--header--bottom__author"
                v-html="
                  $root.$i18n.locale == 'es'
                    ? this.project.authors[0].nameCastellano
                      ? this.project.authors[0].nameCastellano
                      : this.project.authors[0].nameValencia
                    : this.project.authors[0].nameValencia
                    ? this.project.authors[0].nameValencia
                    : this.project.authors[0].nameCastellano
                "
              ></div>
              <div class="project--main--info--header--bottom__insights">
                <div class="insight-item stars">
                  <span>
                    <img src="@/assets/icons/icon-star-black.svg" alt="" />
                  </span>
                  {{ this.project.starsNumber }}
                </div>
                <div class="insight-item likes">
                  <span>
                    <img src="@/assets/icons/icon-heart-black.svg" alt="" />
                  </span>
                  {{ this.project.favoritesNumber }}
                </div>
                <div class="insight-item visualizations">
                  <span>
                    <img src="@/assets/icons/icon-eye-black.svg" alt="" />
                  </span>
                  {{ this.project.views }}
                </div>
              </div>
            </div>
          </header>
          <main class="project--main--info--content">
            <div class="project--main--info--content__description content-item">
              <h3 class="section-title">
                {{ $t("message.components.projectSingle.description") }}
              </h3>
              <div
                v-html="
                  $root.$i18n.locale == 'es'
                    ? this.project.descriptionShortCastellano
                    : this.project.descriptionShortValencia
                "
              ></div>
            </div>
            <div class="project--main--info--content__tipology content-item">
              <h3 class="section-title">
                {{ $t("message.components.projectSingle.tipology") }}
              </h3>
              <div
                v-html="
                  $root.$i18n.locale == 'es'
                    ? $getProjectCategory(this.project.type).name
                    : $getProjectCategory(this.project.type).nameCat
                "
              ></div>
            </div>
            <div class="project--main--info--content__status content-item">
              <h3 class="section-title">
                {{ $t("message.components.projectSingle.status") }}
              </h3>
              <div
                v-html="
                  $root.$i18n.locale == 'es'
                    ? this.project.currentStatus
                    : this.project.currentStatus
                "
              ></div>
            </div>
            <div class="project--main--info--content__links content-item">
              <h3 class="section-title">
                {{ $t("message.components.projectSingle.links") }}
              </h3>
              <div class="project--main--info--content__links--list">
                <a
                  target="_blank"
                  v-for="(link, i) in project.projectLinks"
                  :key="i"
                  :href="link.url"
                >
                  {{ $t("message.components.projectSingle.externalLink") }},
                </a>
              </div>
            </div>
            <div class="project--main--info--content__file content-item">
              <h3 class="section-title">
                {{ $t("message.components.projectSingle.file") }}
              </h3>

              <button class="download-pdf-button" @click="downloadPdf">
                {{ $t("message.components.projectSingle.download") }}
              </button>
            </div>
          </main>
          <div
            class="project--insights-buttons"
            v-if="this.loggedIn && this.$vuetify.breakpoint.width >= 1024"
          >
            <add-feedback-button
              v-if="isInstitution || isSuperAdmin"
              type="stars"
              :initialSelected="false"
              v-on:click.native="requestProject()"
              ref="starsFeedback"
            ></add-feedback-button>
            <add-feedback-button
              type="likes"
              v-on:click.native="likeProject()"
              :initialSelected="false"
              ref="likesFeedback"
            >
            </add-feedback-button>
            <v-snackbar v-model="snackbar">
              Este proyecto se ha añadido a "Proyectos Guardados" en tu panel de
              usuario
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <div
            class="project--geo-link"
            v-if="project.latitude && project.longitude"
          >
            <span class="project--geo-link__icon"
              ><img src="@/assets/icons/icon-map-marker-black.svg" alt=""
            /></span>
            <button class="project--geo-link__text" @click="goToMap()">
              {{ $t("message.components.projectSingle.showInMap") }}
            </button>
          </div>
        </div>
      </div>
      <div class="project--comments">
        <div class="project--comments__title">
          <h2>
            {{ $t("message.components.projectSingle.comments") }} ({{
              totalComments
            }})
          </h2>
        </div>
        <div class="project--comments__list">
          <comment-card
            v-for="(comment, idx) in comments"
            :comment="comment"
            :key="idx"
          ></comment-card>
          <div class="no-comments--message" v-if="totalComments == 0">
            {{ $t("message.components.projectSingle.noComments") }}
          </div>
        </div>
        <div class="project--comments__input" v-if="this.loggedIn">
          <div
            class="project--coments__input__avatar"
            :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(userColor).dark + ')',
            }"
          >
            <span>{{ userChar }}</span>
          </div>
          <v-text-field
            v-model="newComment"
            :placeholder="$t('message.components.projectSingle.addComment')"
            :label="$t('message.components.projectSingle.addComment')"
            solo
            rounded
            flat
            :dense="false"
            :hide-details="true"
            :single-line="true"
            :clearable="true"
          ></v-text-field>
          <button class="message--submit-btn" @click="commentProject()">
            {{ $t("message.contact.send") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import ProjectService from "@/services/project.services";
import AddFeedbackButton from "@/components/Buttons/AddFeedbackButton.vue";
import ProjectMentionButton from "@/components/Buttons/ProjectMentionButton.vue";
import CommentCard from "@/components/Cards/CommentCard.vue";
import PageLoader from "@/components/Loaders/PageLoader.vue";

import Swiper, { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "ProjectViewCug",
  components: {
    AddFeedbackButton,
    ProjectMentionButton,
    CommentCard,
    PageLoader,
  },
  data() {
    return {
      images: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
          alt: "Squirrel",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
          alt: "Sky",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
          alt: "Bird",
        },
      ],
      snackbar: false,
      project: null,
      isFavorite: false,
      isChat: false,
      loaderColor: "0,0,0",
      loaderBgColor: "255,255,255",
      loading: true,
      newComment: null,
      newCommentAvatar: null,
      isSuperAdmin: this.$store.getters["auth/hasRole"](["ROLE_ADMIN"]),
      isCreator: this.$store.getters["auth/hasRole"](["ROLE_CREATOR"]),
      isInstitution: this.$store.getters["auth/hasRole"](["ROLE_INSTITUTION"]),
      projects: [],
      //prueba
      mainImageUrl: "../../images/projects/2/img96.jpg",
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    userColor() {
      if (this.user && this.user.color) {
        return this.user.color;
      }
      return "default";
    },
    userChar() {
      if (this.user && this.user.name) {
        //if user.name has a space, split it and get the first letter of each word
        if (this.user.name.includes(" ")) {
          const name = this.user.name.split(" ");
          return name[0].charAt(0) + name[1].charAt(0);
        }
        //if user.name has no space, get the two first letters
        return this.user.name.substring(0, 2);
      }
      return "XX";
    },
    comments() {
      return this.project.projectComments.map((comment) => {
        return {
          user: {
            name: comment.authorName,
            avatar: null,
            pronoun:
              this.$root.$i18n.locale == "es"
                ? this.$getPronouns(comment.pronoum).nameCat
                : this.$getPronouns(comment.pronoum).nameCastellano,
          },
          date: new Date(comment.date).getTime(),
          text: comment.text,
        };
      });
    },
    totalComments() {
      return this.comments.length;
    },
    projectId() {
      return this.$route.params.id;
    },
  },
  watch: {
    projectId() {
      this.init();
    },
  },
  methods: {
    goToPreviousProject() {
      this.loading = true;
      this.getAllProjects().then(() => {
        const currentProject = this.projects.find(
          (project) => project.id === this.project.id
        );
        const currentProjectIndex = this.projects.indexOf(currentProject);
        if (currentProjectIndex < this.projects.length - 1) {
          this.$router.push({
            name: "ProjectSingle",
            params: {
              id: this.projects[currentProjectIndex + 1].id,
              slug: ProjectService.getSlugFromTitle(
                this.projects[currentProjectIndex + 1].titleValencia
              ),
            },
          });
          //refresh page
          this.$router.go();
        } else {
          this.$router.push({
            name: "ProjectSingle",
            params: {
              id: this.projects[0].id,
              slug: ProjectService.getSlugFromTitle(
                this.projects[0].titleValencia
              ),
            },
          });
          //refresh page
          this.$router.go();
        }
      });
    },
    goToNextProject() {
      this.loading = true;
      this.getAllProjects().then(() => {
        const currentProject = this.projects.find(
          (project) => project.id === this.project.id
        );
        const currentProjectIndex = this.projects.indexOf(currentProject);
        if (currentProjectIndex > 0) {
          this.$router.push({
            name: "ProjectSingle",
            params: {
              id: this.projects[currentProjectIndex - 1].id,
              slug: ProjectService.getSlugFromTitle(
                this.projects[currentProjectIndex - 1].titleValencia
              ),
            },
          });
          //refresh page
          this.$router.go();
        } else {
          this.$router.push({
            name: "ProjectSingle",
            params: {
              id: this.projects[this.projects.length - 1].id,
              slug: ProjectService.getSlugFromTitle(
                this.projects[this.projects.length - 1].titleValencia
              ),
            },
          });
          //refresh page
          this.$router.go();
        }
      });
    },
    async getAllProjects() {
      const projects = await ProjectService.getAllPublic();
      this.projects = projects.content;
      this.loading = false;
    },
    initSwiperProjectImages() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiperProjects, {
        // Disable preloading of all images
        modules: [Navigation, Pagination],
        // grabCursor: true,
        slidesPerView: 1,
        // centeredSlides: true,
        spaceBetween: 16,
        // initialSlide: 3,
        // loop: true,

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-custom-next",
          prevEl: ".swiper-button-custom-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    },
    init() {
      this.loading = true;
      this.getProject();
      if (this.$store.state.auth.status.loggedIn) {
        this.getMyFavoriteProjects();
      }
    },
    checkIfFavorite() {
      if (this.savedProjects) {
        this.isFavorite = false;
        this.savedProjects.forEach((project) => {
          if (project.id === this.project.id) {
            this.setFavoriteButton(true);
            this.isFavorite = true;
          }
        });
      }
    },
    setFavoriteButton(val) {
      if (this.$refs.likesFeedback) {
        this.$refs.likesFeedback.isSelected = val;
      }
    },
    setChatButton(val) {
      if (this.$refs.chatFeedback) {
        this.$refs.chatFeedback.isSelected = val;
      }
    },
    getProject() {
      ProjectService.getProject(this.$route.params.id)
        .then((response) => {
          this.project = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.initSwiperProjectImages();
        });
    },
    getMyFavoriteProjects() {
      ProjectService.getMyFavoritesProjectsNoCommon()
        .then((response) => {
          this.savedProjects = response;
          this.checkIfFavorite();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    likeProject() {
      if (this.loggedIn) {
        if (this.isFavorite) {
          ProjectService.unlikeProject(this.$route.params.id)
            .then(() => {
              this.init();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          ProjectService.likeProject(this.$route.params.id)
            .then(() => {
              this.init();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    requestProject() {
      if (this.loggedIn) {
        if (this.isChat) {
          ProjectService.unrequestChat(this.$route.params.id)
            .then(() => {
              this.snackbar = true;
              this.init();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          ProjectService.requestChat(this.$route.params.id)
            .then(() => {
              this.snackbar = true;
              this.init();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    commentProject() {
      if (this.loggedIn) {
        if (this.newComment) {
          ProjectService.commentProject(this.$route.params.id, this.newComment)
            .then(() => {
              this.snackbar = true;
              this.init();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    downloadPdf() {
      this.exportToPDF();
    },
    goToMap() {
      //router push to MapCug with param id of project id
      this.$router.push({
        name: "MapCugProject",
        params: { id: this.project.id, slug: this.$route.params.slug },
      });
    },
    exportElement() {
      let element = document.getElementById("element-to-print");
      html2pdf(element, {
        margin: 1,
        jsPDF: { orientation: "landscape" },
        html2canvas: { scale: 2, useCORS: true },
        filename: `${
          this.$root.$i18n.locale == "es"
            ? this.project.titleCastellano
            : this.project.titleValencia
        }XX.pdf`,
      });
    },

    exportToPDF() {
      //project links to pdf
      let links = "";
      this.project.projectLinks.forEach((element) => {
        // eslint-disable-next-line no-const-assign
        links += `<a href="${element.url}" "target="_blank" class="project-link">${element.url},</a>`;
      });
      //project authors to pdf
      let authorsCat = "";
      let authorsEs = "";
      this.project.authors.forEach((element) => {
        // eslint-disable-next-line no-const-assign
        authorsCat += `<span>${element.nameValencia}, </span> `;
        authorsEs += `<span>${element.nameCastellano}, </span>`;
      });
      //project images to pdf
      let mainImages = "";
      let secondaryImages = "";
      // eslint-disable-next-line no-unused-vars
      let imagesCount = 0;
      this.project.projectMainImages.forEach((element) => {
        // add 1 to images count
        imagesCount++;
        console.log(element, "imagen");
        //if key is multiple of 3, add a break
        // if (imagesCount % 3 == 0) {
        //   mainImages += `<img src="${element.imageUrl}" class="test-image afterBreak"  style="max-height: 95vh; object-fit: contain;"/>
        //   <h1></h1>`;
        // } else {
        // Usando afterBreak para que no se corte la imagen
        mainImages += `<img src="${element.imageUrl}" class="test-image afterBreak"  style="max-height: 95vh; object-fit: contain;" />`;
        // }
      });
      this.project.projectImages.forEach((element) => {
        // add 1 to images count
        imagesCount++;
        //if key is multiple of 3, add a break
        // if (imagesCount % 3 == 0) {
        //   secondaryImages += `<img style="object-fit:none; background-image: url(${element.imageUrl});" class="test-image afterBreak" />
        //   <h1></h1>`;
        // } else {
        secondaryImages += `<img src="${element.imageUrl}" class="test-image afterBreak"  style="max-height: 95vh; object-fit: contain;" />`;
        // }
      });
      const data =
        `<div class="text-left"><div class="main-logo-inner-content"><div class="main-logo-title">CUG</div>
      <div class="main-logo-description">càtedra urbanisme i <span class="main-logo-description-span">gènere</span></div></div></div>` +
        `<div class="a4">
    <main class="project--info--wrapper" id="page1el">
      <h1 class="project--info__title">
        ${
          this.$root.$i18n.locale == "es"
            ? this.project.titleCastellano
            : this.project.titleValencia
        }
      </h1>
      <div class="project--info--field authors">
        <h2>${this.$t("message.components.projectSingle.authors")}</h2>
        <div>${this.$root.$i18n.locale == "es" ? authorsEs : authorsCat}</div>
      </div>
      <div class="project--info--field year">
        <h2>${this.$t("message.components.projectSingle.year")}</h2>
        <div>${this.project.year}</div>
      </div>
      <div class="project--info--field short-description">
        <h2>${this.$t("message.components.projectSingle.shortDescription")}</h2>
        <div>${
          this.$root.$i18n.locale == "es"
            ? this.project.descriptionShortCastellano
            : this.project.descriptionShortValencia
        }</div>
      </div>
      <div class="project--info--field tipology">
        <h2>${this.$t("message.components.projectSingle.tipology")}</h2>
        <div>${
          this.$root.$i18n.locale == "es"
            ? this.$getProjectCategory(this.project.type).name
            : this.$getProjectCategory(this.project.type).nameCat
        }</div>
      </div>
      <div class="project--info--field status">
        <h2>${this.$t("message.components.projectSingle.status")}</h2>
        <div>${this.project.currentStatus}</div>
      </div>
      <div class="project--info--field location">
        <h2>${this.$t("message.components.projectSingle.location")}</h2>
        <div>${this.project.placeName}</div>
      </div>
      <div class="project--info--field recognisment">
        <h2>${this.$t("message.components.projectSingle.recognisment")}</h2>
        ${
          this.project.prize
            ? `<div><a href="#enlace-a-premio" target="_blank">${this.project.prize} - ${this.project.edition}</a></div>`
            : `<div>${this.$t(
                "message.components.projectSingle.noRecognisment"
              )}</div>`
        }</a>
      </div>
      <div class="project--info--field links">
        <h2>${this.$t("message.components.projectSingle.links")}</h2>
        <div>${links}</div>
      </div>
      <div class="project--info--field long-description">
        <h2>${this.$t("message.components.projectSingle.longDescription")}</h2>
        <div>${
          this.$root.$i18n.locale == "es"
            ? this.project.descriptionLongCastellano
            : this.project.descriptionLongValencia
        }</div>
      </div>
    </main>
    <div id="after1" class="project--images">
      <h1 class="project--images--title">${this.$t(
        "message.components.projectSingle.projectImages"
      )}</h1>
      <div class="project--images--main-images">
        ${mainImages}
      </div>
      <div class="project--images--secondary-images">
        ${secondaryImages}
      </div>
  </div>`;

      html2pdf(data, {
        margin: 0,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { orientation: "portrait" },
        filename: `${
          this.$root.$i18n.locale == "es"
            ? this.project.titleCastellano
            : this.project.titleValencia
        }.pdf`,
        // Quitar after1 si queremos que las imagenes vayan inmediatamente después del texto
        pagebreak: { before: ["#after1"], after: [".afterBreak"] },
        enableLinks: true,
      });
    },
  },
};
</script>

<style>
.project--info--wrapper,
.project--images {
  padding: 32px;
  padding-top: 15px;
}

h1.project--info__title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  max-width: 70%;
}

.project--info--field {
  margin-bottom: 8px;
}

.project--info--field h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 4px;
  position: relative;
  display: inline-block;
}

.project--info--field a {
  display: inline-block;
  margin-right: 4px;
  width: auto;
  font-style: italic;
}

.project--info--field h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgb(var(--bodyColor));
}

.project--images--main-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project--images--secondary-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-width: 250px;
  min-height: 300px;
  margin: 20px;
}
</style>
<style lang="scss" scoped>
.text-left {
  text-align: left;
  padding-left: 200px;
}

.page-project-single {
  min-height: var(--app-height);
  padding-bottom: 24px;

  @media (min-width: 1024px) {
    padding-bottom: 32px;
  }

  .page-title {
    margin: 0 auto;
    padding-top: calc(var(--base) * 13);
    margin-bottom: calc(var(--base) * 2.25);
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: calc(var(--base) * 6);
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      color: rgb(var(--bodyColor));
    }

    @media (min-width: 1024px) {
      padding-top: calc(var(--app-height) * 0.09979633401);
      margin-bottom: calc(var(--app-height) * 0.08757637475);
    }
  }

  //Caja principal del proyecto
  .project--main {
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    overflow: hidden;
    border: solid 2px rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 2.5);
    position: relative;
    max-width: calc(100% - calc(var(--base) * 4.75));
    box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.2);
    display: block;

    @media (min-width: 1024px) {
      // max-height: calc(var(--app-height) * 0.6293279022);
      max-width: 83.73015873vw;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      column-gap: calc(var(--base) * 2.5);
      border-radius: calc(var(--base) * 3.75);
      box-shadow: 5px 8px 20px 4px rgba(0, 0, 0, 0.25);
    }

    .project--main--image {
      border-radius: calc(var(--base) * 2.5);
      border: solid 2px rgb(var(--bodyColor));
      width: calc(100% + 4px);
      aspect-ratio: 2/3;
      margin-top: -2px;
      margin-left: -2px;
      overflow: hidden;
      box-sizing: border-box;
      position: relative;

      @media (min-width: 1024px) {
        aspect-ratio: auto;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        grid-column: 1 / span 3;
        border-radius: calc(var(--base) * 3.75);
      }

      .swiper {
        height: 100%;
        width: 100%;
        position: relative;

        .swiper-slide {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .swiper-navigation-wrapper {
          padding: 0 calc(var(--base) * 2);
          // width: 100%;
          position: absolute;
          z-index: 10;
          top: calc(var(--base) * 1.375);
          left: calc(var(--base) * 1.375);

          display: flex;
          justify-content: space-between;
          gap: 8px;
          pointer-events: none;

          .swiper-button {
            cursor: pointer;
            pointer-events: all;
          }
        }
      }

      .project--mention {
        position: absolute;
        top: calc(var(--base) * 2.75);
        left: calc(var(--base) * 2.875);
      }

      .project--insights-buttons {
        &.movil {
          position: absolute;
          display: flex;
          flex-wrap: nowrap;
          column-gap: calc(var(--base) * 2.75);
          right: calc(var(--base) * 1.625);
          bottom: calc(var(--base) * 1.75);
          z-index: 100;
        }
      }
    }

    .project--main--info {
      width: 100%;
      padding: calc(var(--base) * 1.25);

      @media (min-width: 1024px) {
        grid-column: 4 / span 4;
        padding: 32px;
        padding-left: calc(var(--base) * 6.25);
        padding-right: calc(var(--base) * 10.5);
        padding-bottom: 0;
        overflow: hidden;
        margin-bottom: calc(var(--base) * 2.5);
      }

      .project--main--info--header {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .project--main--info--header--top {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: solid 1px rgb(var(--bodyColor));
          padding-bottom: 4px;
          color: rgb(var(--bodyColor));
          width: 100%;

          &__title {
            font-size: 24px;
            line-height: calc(var(--base) * 3.75);
            font-weight: 700;
            max-width: 90%;
          }

          &__date {
            font-size: calc(var(--base) * 2.5);
            line-height: 1;
            font-weight: 700;
          }
        }

        .project--main--info--header--bottom {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: space-between;
          gap: calc(var(--base) * 1.5);
          padding-top: 8px;

          &__author {
            font-size: calc(var(--base) * 2);
            line-height: 1.3;
            width: 100%;
          }

          &__insights {
            margin-top: calc(var(--base) * 0.2);
            display: flex;
            flex-wrap: nowrap;
            font-size: calc(var(--base) * 1.875);
            line-height: 1;
            font-weight: 700;
            max-width: 45%;
            width: 40%;
            justify-content: flex-end;
            gap: 8px;

            @media (min-width: 1024px) {
              width: 100%;
              max-width: 50%;
              gap: calc(var(--base) * 1.875);
              justify-content: flex-end;
            }

            .insight-item {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              column-gap: 4px;

              span {
                // max-height: calc(var(--base) * 1.375);
                // min-height: calc(var(--base) * 1.375);
                // min-width: calc(var(--base) * 1.375);
                // max-width: calc(var(--base) * 1.375);
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                }
              }
            }
          }
        }
      }

      .project--main--info--content {
        .content-item {
          margin-bottom: calc(var(--base) * 2);

          &.project--main--info--content__description {
            margin-bottom: calc(var(--base) * 2);
            font-size: calc(var(--base) * 2);
            line-height: calc(var(--base) * 3.125);

            .section-title {
              margin-bottom: calc(var(--base) * 1.75);
            }
          }

          .project--main--info--content__links--list {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 4px;
          }

          .section-title {
            width: 100%;
            font-size: calc(var(--base) * 2.375);
            line-height: 24px;
            border-bottom: solid 1px rgb(var(--bodyColor));
            max-width: calc(var(--base) * 32.5);
            padding-bottom: 4px;
            margin-bottom: 4px;
          }
        }

        button,
        a {
          text-decoration: underline;
        }
      }

      .project--insights-buttons {
        position: absolute;
        right: calc(var(--base) * 5.875);
        bottom: calc(var(--base) * 7.25);
        display: flex;
        flex-wrap: nowrap;
        column-gap: calc(var(--base) * 2.75);
      }

      .project--geo-link {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        background-color: rgb(var(--bodyColor));
        color: rgb(var(--bodyBg));
        padding: calc(var(--base) * 1.25);
        width: 90%;
        margin: 0 auto;
        border-radius: calc(var(--base) * 1.25);
        margin-top: calc(var(--base) * 3.875);
        margin-bottom: calc(var(--base) * 3.875);

        @media (min-width: 1024px) {
          position: absolute;
          right: calc(var(--base) * 5.875);
          bottom: calc(var(--base) * 1.25);
          color: rgb(var(--bodyColor));
          justify-content: flex-end;
          background-color: transparent;
          padding: 0;
          width: auto;
          margin: 0;
        }

        &__icon {
          display: none;

          @media (min-width: 1024px) {
            display: block;
            max-width: calc(var(--base) * 1.625);
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        &__text {
          font-size: calc(var(--base) * 2);
          line-height: 1;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 4px;

          &:after {
            content: url(@/assets/icons/arrow-right.svg);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          @media (min-width: 1024px) {
            font-size: calc(var(--base) * 1.75);
            text-decoration: underline;
            text-align: right;
            white-space: nowrap;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .project--comments {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    border: solid 2px rgb(var(--bodyColor));
    border-radius: calc(var(--base) * 2.5);
    position: relative;
    max-width: calc(100% - calc(var(--base) * 4.75));
    box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.2);
    padding: calc(var(--base) * 1.75) calc(var(--base) * 1.25);
    margin-top: calc(var(--base) * 1.5);
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      max-height: calc(var(--base) * 77.25);
      max-width: 83.7%;
      border-radius: calc(var(--base) * 3.75);
      box-shadow: 5px 8px 20px 4px rgba(0, 0, 0, 0.25);
      padding: calc(var(--base) * 3.75) calc(var(--base) * 6.375);
      margin-top: calc(var(--base) * 3.625);
    }

    &__title {
      font-size: calc(var(--base) * 2.5);
      line-height: 24px;
      border-bottom: solid 1px rgb(var(--bodyColor));
      padding-bottom: calc(var(--base) * 1.25);

      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 3.5);
        line-height: 32px;
        padding-bottom: calc(var(--base) * 2);
      }
    }

    &__input {
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      border-top: solid 1px rgb(var(--bodyColor));
      padding: 24px 0;
      padding-bottom: 0;

      .project--coments__input__avatar {
        width: calc(var(--base) * 6);
        height: calc(var(--base) * 6);
        border-radius: 50%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        font-size: calc(var(--base) * 2);
        transition: all ease-out 0.2s;
        margin-right: 8px;
      }

      .message--submit-btn {
        padding: calc(var(--base) * 0.2) 8px;

        @media (min-width: 1024px) {
          padding: calc(var(--base) * 0.2) calc(var(--base) * 2);
        }
      }
    }

    .no-comments--message {
      margin-top: calc(var(--base) * 2.5);
      margin-bottom: calc(var(--base) * 2.5);
      font-size: calc(var(--base) * 2);
      line-height: 24px;
      font-style: italic;
    }
  }
}

.navigation-buttons {
  padding: 0 calc(var(--base) * 3.5);
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
</style>

<style>
.main-logo-inner-content {
  max-width: calc(var(--base) * 21.38);
  max-height: calc(var(--base) * 8.125);
  width: 100%;
  height: 100%;
  background-color: rgb(var(--bodyColor));
  border-radius: calc(var(--base) * 0.5);
  overflow: hidden;
  color: rgb(var(--bodyBg));
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  -moz-column-gap: calc(var(--base) * 2);
  column-gap: calc(var(--base) * 2);
  padding: calc(var(--base) * 1.25) calc(var(--base) * 0.9);
  margin: 20px;
  margin-left: 32px;
}

.main-logo-title {
  font-size: calc(var(--base) * 4.5);
  letter-spacing: -0.03em;
  text-transform: lowercase;
  margin-bottom: calc(var(--base) * 0.6);
  font-weight: bold;
}

.main-logo-description {
  font-size: calc(var(--base) * 1.5);
  line-height: 1;
  text-align: calc(var(--base) * 0.625);
  text-align: left;
  margin: 0;
  min-width: calc(var(--base) * 9);
  max-width: calc(var(--base) * 9);
  width: 100%;
}

.main-logo-description-span {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  text-align: right;
  height: 100%;
  width: 100%;
}

.project--main {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  border: solid 2px rgb(var(--bodyColor));
  position: relative;

  max-width: 83.7%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: calc(var(--base) * 2.5);
  border-radius: calc(var(--base) * 3.75);
  box-shadow: 5px 8px 20px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 1024px) {
  .project--main {
    max-height: calc(var(--base) * 77.25);
  }
}

.project--main--image {
  aspect-ratio: 2/3;
  margin-top: -2px;
  margin-left: -2px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  grid-column: 1 / span 5;
  border-radius: calc(var(--base) * 3.75);
}

.project--main--image--img--container {
  width: 100%;
  height: 100%;
}

.project--main--image--img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
