<template>
  <div class="overlay-wrapper" :class="[this.open ? 'open' : '']">
    <div class="overlay-card" ref="catColor">
      <div class="overlay-card--inner">
        <header class="overlay-card--inner--header">
          <div
            class="overlay-card--inner--header__icon"
            v-if="this.$vuetify.breakpoint.width >= 1024"
          >
            <img
              :src="cat.icon.black"
              :alt="this.$t(`message.categories.${cat.id}.title`)"
            />
          </div>

          <div class="overlay-card--inner--header__index">
            0{{ cat.number }}
          </div>
          <h3 class="overlay-card--inner--header__name">
            {{ $t(`message.categories.${cat.id}.title`) }}
          </h3>
        </header>
        <main
          class="overlay-card--inner--content"
          v-html="this.$t(`message.categories.${cat.id}.description`)"
        />
        <button
          @click="downloadGuide"
          class="max-h-12 my-4 flex justify-center items-center gap-2 bg-black text-white px-4 py-2 rounded-lg text-center text-lg font-medium leading-[normal] whitespace-nowrap"
        >
          <img src="~@/assets/icons/icon-download-white.svg" alt="" />
          {{ $t("message.home.guide.button") }}
        </button>
        <button class="overlay-card--inner--link" @click="goToArxiu(cat)">
          {{ $t(`message.components.overlayCard.title`) }}
          <span>
            <img
              src="@/assets/icons/icon-arrow-circle-right.svg"
              alt=""
              v-if="this.$vuetify.breakpoint.width < 1024"
            />
            <img src="@/assets/icons/chevron-right.svg" alt="" v-else />
          </span>
        </button>
      </div>
      <button
        class="overlay-card--toggle"
        @click="this.toggleOverlay"
        v-if="this.$vuetify.breakpoint.width < 1024"
      >
        <span class="overlay-card--toggle__icon">
          <img src="@/assets/icons/icon-cross-black.svg" alt="" />
        </span>
        <span class="overlay-card--toggle__text">{{
          $t("message.general.close")
        }}</span>
      </button>
      <div
        class="overlay-card--cat-icon--movil"
        v-if="this.$vuetify.breakpoint.width < 1024"
      >
        <img
          :src="cat.icon.white"
          :alt="this.$t(`message.categories.${cat.id}.title`)"
        />
      </div>
    </div>
    <div
      class="overlay-viewport-background"
      @click="this.toggleOverlay"
      v-show="this.open"
      v-if="this.$vuetify.breakpoint.width >= 1024"
    ></div>
  </div>
</template>

<script>
import projectServices from "@/services/project.services";

export default {
  name: "OverlayCard",
  props: {
    to: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      open: false,
      cat: this.$getProjectCategory("SOSTENIBILIDAD"),
    };
  },
  methods: {
    changeColor() {
      this.$refs.catColor.style.setProperty("--catColor", this.cat.color);
    },
    toggleOverlay() {
      this.open = !this.open;
      if (this.open) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    updateCat(cat) {
      this.cat = cat;
      this.changeColor();
    },
    goToArxiu(cat) {
      this.$router.push({
        name: "ArchiveCugFiltered",
        params: {
          id: cat.id,
          slug: projectServices.getSlugFromTitle(cat.nameCat),
        },
      });
    },
    downloadGuide() {
      if (this.$root.$i18n.locale === "es") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/173154437/GUIA+PERSPECTIVA+DE+GENERO+EN+ACTUACIONES+URBANAS_Valenciano160321.pdf/7642f337-0d9f-4590-bd53-99d3a109460b",
          "_blank"
        );
        return;
      } else if (this.$root.$i18n.locale === "ca") {
        window.open(
          "https://politicaterritorial.gva.es/documents/20551182/174929742/3.+GUIA+PERSPECTIVA+DE+G%C3%88NERE+EN+ACTUACIONS+URBANES.pdf/4eedc847-c7c8-51af-6eb5-c068386e55d3?t=1664781422779",
          "_blank"
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px blue;
// }
.overlay-wrapper {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--app-height);
  z-index: 5000;
  @media (min-width: 1024px) {
    z-index: auto;
  }

  &.open {
    & .overlay-card {
      right: auto;
      top: 0;
      @media (min-width: 1024px) {
        top: calc(var(--base) * 17);
        right: calc(var(--base) * -1);
      }
    }
  }
}
.overlay-card {
  $bgColor: var(--catColor);
  pointer-events: all;
  background-color: rgba($bgColor, 1);
  width: 100%;
  height: 100%;
  position: fixed;
  right: auto;
  top: 100%;
  transition: all ease-out 0.5s;
  @media (min-width: 1024px) {
    width: 53vw;
    height: calc(var(--app-height) * 0.76);
    border-radius: 100vw 0 0 100vw;
    right: -100%;
    top: calc(var(--base) * 17);
    z-index: 100;
  }

  .overlay-card--inner {
    padding: calc(var(--base) * 5.625);
    padding-top: 24%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      padding-top: calc(var(--base) * 11.3);
      padding-right: calc(var(--base) * 7);
      padding-bottom: calc(var(--base) * 5);
      padding-left: 0;
      width: 73%;
      float: right;
      align-items: flex-end;
    }
  }
  .overlay-card--inner--header {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: calc(var(--base) * 1.5);
    margin-bottom: calc(var(--base) * 3);
    width: 100%;
    max-width: 90%;
    @media (min-width: 1024px) {
      width: auto;
      max-width: auto;
    }

    &__icon {
      width: 100%;
      height: auto;
      max-width: calc(var(--base) * 6);
      margin-bottom: calc(var(--base) * 0.8);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__index {
      font-size: calc(var(--base) * 6);
      line-height: 0.85;
      font-weight: 400;
      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 10.63);
      }
    }
    &__name {
      font-size: calc(var(--base) * 2.75);
      line-height: calc(var(--base) * 3);
      font-weight: 700;
      position: relative;
      padding-left: calc(var(--base) * 1.5);
      display: inline-block;
      max-width: 17ch;
      &:after {
        content: "";
        display: block;
        height: 100%;
        width: calc(var(--base) * 0.4);
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgb(var(--bodyColor));
      }
      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 4.5);
        line-height: calc(var(--base) * 5);
      }
    }
  }
  .overlay-card--inner--content {
    font-size: calc(var(--base) * 2.06);
    line-height: calc(var(--base) * 3);
    font-weight: 400;
    margin-bottom: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      text-align: right;
    }
  }
  .overlay-card--inner--link {
    margin-top: auto;
    font-size: calc(var(--base) * 2);
    line-height: calc(var(--base) * 2.5);
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    column-gap: calc(var(--base) * 2);
    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 2.69);
      line-height: calc(var(--base) * 3);
      max-width: calc(var(--base) * 46.25);
      text-align: right;
    }
    span {
      display: block;
      height: 100%;
      width: calc(var(--base) * 7);
      @media (min-width: 1024px) {
        width: calc(var(--base) * 5);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.overlay-viewport-background {
  display: none;
  @media (min-width: 1024px) {
    display: block;
    pointer-events: all;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: all ease-out 0.2s;
  }
}

.overlay-card--toggle {
  position: absolute;
  top: calc(var(--base) * 4.5);
  right: calc(var(--base) * 2.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(var(--bodyColor));
  text-transform: uppercase;
  font-size: calc(var(--base) * 1.25);
  line-height: calc(var(--base) * 2.5);
  &__icon {
    margin-bottom: calc(var(--base) * 0.5);
  }
}
.overlay-card--cat-icon--movil {
  position: absolute;
  top: calc(var(--base) * 1);
  left: calc(var(--base) * -2.25);
  width: calc(var(--base) * 25.625);
  opacity: 0.2;
  z-index: -1;
}
</style>
