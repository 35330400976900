<template>
  <div class="page page-login">
    <div class="login">
      <div class="login--inner">
        <h1 class="login--title">{{ $t("message.forms.login") }}</h1>
        <v-form class="login--form" id="loginForm" @submit.prevent="validateUser">
          <v-text-field
            v-model="email"
            :label="$t('message.forms.email')"
            :placeholder="$t('message.forms.email')"
            :rules="emailRules"
            outlined
            class="login--form--input"
            :dense="this.$vuetify.breakpoint.width < 1024"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :label="$t('message.forms.password')"
            :placeholder="$t('message.forms.password')"
            :rules="passwordRules"
            outlined
            class="login--form--input"
            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            :dense="this.$vuetify.breakpoint.width < 1024"
          ></v-text-field>
          <v-btn
            class="login--form--button"
            :disabled="!isFormValid || this.$v.$invalid || sending"
            :loading="sending"
            elevation="2"
            :x-large="this.$vuetify.breakpoint.width >= 1024"
            type="submit"
          >
            {{ $t("message.forms.login") }}
          </v-btn>
          
        </v-form>
        <div class="login--forgot-password">
          <router-link :to="{ name: 'RecoverPassword' }">{{
            $t("message.forms.forgotPassword")
          }}</router-link>
        </div>
        <div class="login--register-link">
          {{ $t("message.forms.noAccount")
          }}<router-link :to="{ name: 'RegisterForm' }">{{
            $t("message.forms.noAccountLink")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'LoginCug',
    mixins: [validationMixin],
    components: {
    },
    data(){
      return{
        value: String,
        email: '',
        password: '',
        emailRules: [
          v => !!v || this.$t('message.forms.emailRequired'),
          v => /.+@.+\..+/.test(v) || this.$t('message.forms.emailInvalid'),
        ],
        passwordRules: [
          v => !!v || this.$t('message.forms.passwordRequired'),
        ],
        isFormValid:true,
        sending:false,

      }
    },
    validations: {
      email: {
        required,
      },
      password: {
        required,
      },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    logged(){
      return this.$store.state.auth;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push({ name: "UserPanelInicio" });
    }
  },
  methods: {
    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.handleLogin();
      }
    },
    handleLogin() {
       this.sending = true;
      const user = {};
      user.username = this.email;
      user.password = this.password;

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push({ name: "UserPanelInicio" });
        },
        (error) => {
          if (error.response.status === 403) {
            this.message = 'Not valid username or password';
          } else {
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
          }
          this.sending = false;
        },
      );
    },
    
  }

}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  min-height: var(--app-height);
  max-height: var(--app-height);
  overflow: hidden;
}
.login {
  height: 100%;
  width: 100%;
  max-width: 86.9%;
  margin: 0 auto;
  margin-top: calc(var(--app-height) * 0.2773613193);
  @media (min-width: 1024px) {
    max-width: 51%;
    margin-left: calc(var(--base) * 10.75);
    margin-top: calc(var(--app-height) * 0.25);
  }

  .login--title {
    font-size: calc(var(--base) * 4.25);
    font-weight: 500;
    color: rgba(var(--bodyColor), 1);
    margin-bottom: calc(var(--base) * 7.25);
    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 6);
      margin-bottom: calc(var(--app-height) * 0.07926829268);
    }
  }

  .login--form--input {
    margin-bottom: calc(var(--base) * 2);
    &:last-of-type {
      margin-bottom: calc(var(--base) * 1);
    }
    @media (min-width: 1024px) {
      margin-bottom: calc(var(--base) * 2);
    }
  }
  .login--form--button {
    margin-bottom: calc(var(--base) * 4.375);
  }
  .login--forgot-password {
    text-decoration: none;
    font-size: calc(var(--base) * 2.5);
    margin-bottom: calc(var(--base) * 1.375);
    @media (min-width: 1024px) {
      text-decoration: underline;
    }
  }
  .login--register-link {
    font-size: calc(var(--base) * 2.5);
    a {
      font-weight: 400;
      text-decoration: underline;
      @media (min-width: 1024px) {
        text-decoration: none;
      }
      &:hover {
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
}
</style>