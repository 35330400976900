<template>
  <div class="form-step--wrapper form-upload-edit--step-1">
    <div class="form--content form-upload-edit--step-1--content">
      <div class="lang-form-selector">
        <a class="lang-form-selector--item"
          :class="activeLang === 'valenciano' ? 'selected' : ''" @click="activeLang = 'valenciano'">
          Valencià
        </a>
        |
        <a  class="lang-form-selector--item"
          :class="activeLang === 'castellano' ? 'selected' : ''" @click="activeLang = 'castellano'">
          Castellano
        </a>
      </div>
      <div class="form--grid">
        <div class="form--column left-col">
          <div class="form--row">
            <v-text-field v-model="project.titleValencia"
              :label="$t('message.forms.projectNameCat')"
              :placeholder="$t('message.forms.projectNameCat')" :rules="projectNameRules" required
              outlined :dense="this.$vuetify.breakpoint.width < 1024"
              v-if="activeLang === 'valenciano'"></v-text-field>
            <v-text-field v-model="project.titleCastellano"
              :label="$t('message.forms.projectNameEs')"
              :placeholder="$t('message.forms.projectNameEs')" :rules="projectNameRules" required
              outlined :dense="this.$vuetify.breakpoint.width < 1024" v-else></v-text-field>
            <v-text-field class="form-input--year" v-model="project.year"
              :label="$t('message.forms.year')" :placeholder="$t('message.forms.year')"
              :rules="projectYearRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"></v-text-field>
          </div>
          <div class="form--row">
            <v-select v-model="project.type" :items="tipologyItems"
              :label="$t('message.forms.tipology')" :placeholder="$t('message.forms.tipology')"
              :rules="tipologyRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"></v-select>
            <v-select v-model="project.currentStatus" :items="statusItems"
              :label="$t('message.forms.status')" :placeholder="$t('message.forms.status')"
              :rules="statusRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"></v-select>
          </div>
          <div class="form--row">
            <v-text-field class="form-input--authors" v-model="project.authors[0].nameValencia"
              :label="$t('message.forms.authorsCat')" :placeholder="$t('message.forms.authorsCat')"
              :rules="projectAuthorsCatRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"
              v-if="activeLang === 'valenciano'"></v-text-field>
            <v-text-field class="form-input--authors" v-model="project.authors[0].nameCastellano"
              :label="$t('message.forms.authorsEs')" :placeholder="$t('message.forms.authorsEs')"
              :rules="projectAuthorsEsRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"
              v-if="activeLang === 'castellano'"></v-text-field>
          </div>
        </div>
        <div class="form--column right-col">
          <div class="form--row">
            <v-text-field v-model="project.descriptionShortValencia"
              :label="$t('message.forms.projectShortDescriptionCat')"
              :placeholder="$t('message.forms.projectShortDescriptionCat')" counter maxlength="300"
              :rules="projectShortDescriptionRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024"
              v-if="activeLang === 'valenciano'"></v-text-field>
            <v-text-field v-model="project.descriptionShortCastellano"
              :label="$t('message.forms.projectShortDescriptionEs')"
              :placeholder="$t('message.forms.projectShortDescriptionEs')"
              :rules="projectShortDescriptionRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024" counter maxlength="300"
              v-else></v-text-field>
          </div>
          <div class="form--row">
            <v-text-field class="coordinates-form-input" v-model="projectCoordinates"
              :label="$t('message.forms.projectCoordinates')"
              :placeholder="$t('message.forms.projectCoordinates')"
              :hint="this.$t('message.contact.optional')" persistent-hint outlined
              :dense="this.$vuetify.breakpoint.width < 1024" @click:append="emitToggleModal()">
              <template v-slot:append>
                <a href="#">
                  <img class="text-field--custom-icon"
                    src="@/assets/icons/icon-question-append-desktop.svg" />
                </a>
              </template>
            </v-text-field>
          </div>
          <div class="form--row">
            <v-file-input v-model="projectImages" :label="
              $t('message.forms.projectSelectFiles') +
              ' ' +
              $t('message.forms.maxFilesThree')
            " prepend-inner-icon="mdi-cloud-upload" :counter="3" prepend-icon="" :placeholder="
  $t('message.forms.projectSelectFiles') +
  ' ' +
  $t('message.forms.maxFilesThree')
" :rules="projectImagesRules" required outlined
              :dense="this.$vuetify.breakpoint.width < 1024" multiple small-chips clearable>
              <template v-slot:selection="{ index, text, url }">
                <v-chip close @click:close="deleteChip(index, text)" @click="openImage(url)" >
                  {{ text }} {{ url }} {{ index }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLang: "valenciano",
      projectNameRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      projectYearRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      tipologyItems: [
        { text: "SOSTENIBILIDAD", value: "SOSTENIBILIDAD" },
        { text: "INSTALACIONES", value: "INSTALACIONES" },
        { text: "ESPACIOS_COMUNES", value: "ESPACIOS_COMUNES" },
        { text: "SEGURIDAD", value: "SEGURIDAD" },
        { text: "SOCIAL", value: "SOCIAL" },
      ],
      tipologyRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      statusItems: [
        { text: "En curso", value: "En curso" },
        { text: "Finalizado", value: "Finalizado" },
      ],
      statusRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      projectShortDescriptionRules: [
        v => !!v || this.$t('message.general.required'),
        v => !!v && v.length <= 300 || this.$t('message.forms.projectShortDescriptionMax'),
      ],
      projectImages: [],
      projectImagesRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      projectCoordinates: "",
      projectCoordinatesRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      projectAuthorsEsRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      projectAuthorsCatRules: [
        v => !!v || this.$t('message.general.required'),
      ],
      project: {
        titleCastellano: null,
        titleValencia: null,
        year: null,
        type: null,
        currentStatus: null,
        authors: [
          {
            nameCastellano: null,
            nameValencia: null,
          }
        ],
        descriptionShortCastellano: null,
        descriptionShortValencia: null,
        projectMainImages: [
          {
            imageUrl: null,
          }
        ],
        latitude: null,
        longitude: null,
        category: null,
        edition: null,
        prize: null,
        projectLinks: [
          {
            url: null,
            title: null
          }
        ],
        placeName: null,
        descriptionLongCastellano: null,
        descriptionLongValencia: null,
      }
    };
  },
  watch: {
    project: {
      handler: function () {
        this.updateData();
      },
      deep: true,
    },
    projectCoordinates: {
      handler: function () {
        this.updateData();
      },
      deep: true,
    },
    projectImages: {
      handler: function () {
        this.updateData();
      },
      deep: true,
    },
  },
  methods: {
    emitToggleModal() {
      this.$emit("toggleModal");
    },
    updateData() {
      this.getLatitudeAndLongitude();
      this.$emit("updateData", this.project, this.projectImages);
    },
    getLatitudeAndLongitude() {
      this.project.latitude = this.projectCoordinates.split(",")[0];
      this.project.longitude = this.projectCoordinates.split(",")[1];
    },
    updateProject(project) {
      this.project = project;
      this.projectCoordinates = project.latitude + "," + project.longitude;
      this.projectImages = project.projectMainImages.map(image => {
        return {
          name: image.imageUrl.split("/").pop(),
          url: image.imageUrl,
        }
      });
    },
    deleteChip(index) {
      // Prompt here with text if required
      this.projectImages.splice(index, 1)
    },
    openImage(url) {
      console.log(url);
      console.log(this.projectImages);
      window.open(url, "_blank");
    },
  },
}

</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.2673463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3149287169);
  }

  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 82.8042328vw;
    margin: 0 auto;
    width: 100%;

    .lang-form-selector {
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 0.5);
      margin-bottom: calc(var(--base) * 2);

      a {
        font-size: calc(var(--base) * 2.25);
        pointer-events: all;
        cursor:pointer;

        &.selected {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .form--grid {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        column-gap: 3.835978836vw;
      }
    }

    .v-input__append-inner {
      .text-field--custom-icon {
        width: calc(var(--base) * 3.5);
        height: calc(var(--base) * 3.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .form--column {
      width: 100%;

      @media (min-width: 1024px) {
        width: 50%;
      }

      .form--row {
        display: flex;
        flex-wrap: nowrap;
        column-gap: calc(var(--base) * 2.25);
        width: 100%;

        .form-input--year {
          width: 100%;
          max-width: 30%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.v-file-input .v-file-input__text.v-file-input__text--chips {
  flex-wrap: wrap;
}
</style>