<template>
  <div class="page page-archive" :class="[loading ? 'overflow-hidden': '']">
    <archive-gradient-scroll></archive-gradient-scroll>
    <div class="page--header page-archive--header">
      <h1 class="page--header__title page-archive--header__title">
        {{ $t("message.archive.title") }}
      </h1>
      <h2 v-if="this.$vuetify.breakpoint.width >= 1024" class="page--header__subtitle page-archive--header__subtitle"
        v-html="this.$t('message.archive.subtitle')"></h2>
    </div>
    <div class="archive--filter-container">
      <archive-filter @updateFilter="updateFilter"></archive-filter>
    </div>
    <div class="filter--cat-container">
      <filter-cat-selector-item :cat="$getProjectCategory('SOSTENIBILIDAD')" 
      :isRouteUp="isRouteUp" @updateFilter="updateType" ref="filterSOSTENIBILIDAD"></filter-cat-selector-item>
      <filter-cat-selector-item :cat="$getProjectCategory('INSTALACIONES')" 
      :isRouteUp="isRouteUp" @updateFilter="updateType" ref="filter_INSTALACIONES"  ></filter-cat-selector-item>
      <filter-cat-selector-item :cat="$getProjectCategory('ESPACIOS_COMUNES')" 
      :isRouteUp="isRouteUp" @updateFilter="updateType" ref="filter_ESPACIOS_COMUNES"></filter-cat-selector-item>
      <filter-cat-selector-item :cat="$getProjectCategory('SEGURIDAD')" 
      :isRouteUp="isRouteUp" @updateFilter="updateType" ref="filter_SEGURIDAD"></filter-cat-selector-item>
      <filter-cat-selector-item :cat="$getProjectCategory('SOCIAL')" 
      :isRouteUp="isRouteUp" @updateFilter="updateType" ref="filter_SOCIAL"></filter-cat-selector-item>
    </div>
    <div class="archive--grid" v-if="projects && this.$vuetify.breakpoint.width >= 1024">
      <page-loader :color="this.color" :bgColor="this.bgColor" v-if="loading"></page-loader>
      <project-card v-for="project in projects" :key="project.id" :title="project.titleCastellano"
        :titleCat="project.titleValencia" :author="project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia" :projectId="project.id"
        :authorCat="project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano" :cat="$getProjectCategory(project.type)"
        :img="project.projectMainImages[0] ? project.projectMainImages[0].imageUrl : project.projectMainImages[0]"
        :stars="project.starsNumber" :likes="project.favoritesNumber" :views="project.views" :isRouteUp="isRouteUp"></project-card>
        <div class="no-results">
          <h2 v-if="projects.length === 0" class="no-results__title" v-text="this.$t('message.archive.noResults')"></h2>
        </div>
    </div>
    <div class="archive--grid" v-if="projects && this.$vuetify.breakpoint.width < 1024">
      <page-loader :color="this.color" :bgColor="this.bgColor" v-if="loading"></page-loader>
      <project-card-movil v-for="project in projects" :key="project.id" :title="project.titleCastellano"
        :titleCat="project.titleValencia" :author="project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia" :projectId="project.id"
        :authorCat="project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano" :cat="$getProjectCategory(project.type)"
        :img="project.projectMainImages[0] ? project.projectMainImages[0].imageUrl : project.projectMainImages[0]"
        :stars="project.starsNumber" :likes="project.favoritesNumber" :views="project.views" :isRouteUp="isRouteUp">
        </project-card-movil>
        <span v-for="project in projects" :key="project.id">{{ project.type }}</span>
    </div>
  </div>
</template>

<script lang="js">
import ProjectService from '@/services/project.services';
import ProjectCard from '@/components/Cards/ProjectCard.vue';
import ProjectCardMovil from '@/components/Cards/ProjectCardMovil.vue';
import ArchiveFilter from '@/components/Filters/ArchiveFilter.vue';
import FilterCatSelectorItem from '@/components/Filters/FilterCatSelectorItem.vue';
import ArchiveGradientScroll from '@/components/Decoration/ArchiveGradientScroll.vue';
import PageLoader from '@/components/Loaders/PageLoader.vue';

export default {
  name: 'ArchiveCug',
  components: {
    ProjectCard,
    ProjectCardMovil,
    ArchiveFilter,
    FilterCatSelectorItem,
    ArchiveGradientScroll,
    PageLoader,
  },
  data() {
    return {
      projects: [],
      filter: {
        year: null,
        status: null,
        title: null,
      },
      filterType: null,
      filterTypeArray: [],
      loading:true,
      color: '0,0,0',
      bgColor: '255,255,255',
    };
  },
  computed: {
    isRouteUp() {
      return !!(this.$route.params.id);
    },
  },
  methods: {
    updateType(val) {
      // const templateFilterTypeArray = "SOCIAL,INSTALACIONES,ESPACIOS_COMUNES,SEGURIDAD,SOSTENIBILIDAD";
      // if val is in filterTypeArray, remove it 
      // else add it
      if (this.filterTypeArray.includes(val)) {
        this.filterTypeArray.splice(this.filterTypeArray.indexOf(val), 1);
      } else {
        this.filterTypeArray.push(val);
      }
      // transform array to string
      this.filterType = this.filterTypeArray.join(',');
      this.getProjectsFiltered();
    },
    async getAllProjects() {
      this.loading = true;
      const projects = await ProjectService.getAllPublic();
      this.projects = projects.content;
      this.loading = false;
    },

    async getProjectsFiltered() {
      this.loading = true;
      const projects = await ProjectService.getPublicFiltered(this.filter.title, this.filter.year, this.filter.status, this.filterType);
      this.projects = projects.content;
      this.loading = false;
    },

    updateFilter(val) {
      this.filter = val;
      this.getProjectsFiltered();
    },
    initTypeFilter(val) {
      val = parseInt(val);
      let cat = this.$getProjectCategoryById(val);
      this.updateType(cat.key);
      this.setFilterType(cat.key);
    },
    setFilterType(val) {
    
      if(val === 'SOSTENIBILIDAD'){
        this.$refs.filterSOSTENIBILIDAD.updateIsSelected(true);
      }else if(val === 'INSTALACIONES'){
        this.$refs.filter_INSTALACIONES.updateIsSelected(true);
      }else if(val === 'SOCIAL'){
        this.$refs.filter_SOCIAL.updateIsSelected(true);
      }else if(val === 'ESPACIOS_COMUNES'){
        this.$refs.filter_ESPACIOS_COMUNES.updateIsSelected(true);
      }else if(val === 'SEGURIDAD'){
        this.$refs.filter_SEGURIDAD.updateIsSelected(true);
      }
    },
  },
  mounted() {
    //if has route params, set filter
    if (this.$route.params.id) {
      this.initTypeFilter(this.$route.params.id);
    }else{
      this.getAllProjects();
    }
  },
}

</script>

<style lang="scss" scoped>
.page-archive{
  min-height:100%;
  // padding-bottom: calc(var(--base) * 5);
  @media (min-width: 1024px) {
    padding-bottom: calc(var(--base) * 10);
  }
}
.page--header {
  position: fixed;
  left: 50%;
  top: calc(var(--app-height) * 0.143928036);
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: calc(var(--base) * 2.875);
  z-index: 200;

  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.07026476578);
    text-align: left;
  }

  &__title {
    font-size: calc(var(--base) * 4.25);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    display: block;

    @media (min-width: 1024px) {
      font-size: calc(var(--base) * 12);
      padding-right: calc(var(--base) * 2.875);
      border-right: solid 3px rgb(var(--bodyColor));
    }
  }

  .page--header__subtitle {
    font-size: calc(var(--base) * 3);
    line-height: calc(var(--base) * 3.5);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.archive--grid {
  width: 100%;
  height:100%;
  padding: 0 calc(var(--base) * 2);
  margin-top: calc(var(--app-height) * 0.3883058471);
  margin-bottom: calc(var(--base) * 16);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: calc(var(--base) * 28.5);
  column-gap: calc(var(--base) * 2.5);
  column-gap: 0;
  gap: calc(var(--base) * 2.5);

  @media (min-width: 1024px) {
    margin: 0 auto;
    margin-top: calc(var(--app-height) * 0.284114053);
    // margin-bottom: calc(var(--app-height) * 0.284114053);
    max-width: 77.51322751vw;
    height:100%;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: calc(var(--base) * 54.125);
    gap: 5%;
  }
}

.filter--cat-container {
  position: fixed;
  left: 50%;
  top: calc(var(--app-height) * 0.2818590705);
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 450px;
  z-index: 150;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: calc(var(--base) * 2);
  row-gap: calc(var(--base) * 2);

  @media (min-width: 1024px) {
    width: auto;
    max-width: 100%;
    flex-direction: column;
    top: calc(var(--app-height) * 0.2861507128);
    top: 50%;
    left: calc(var(--app-height) * 0.03373015873);
    row-gap: calc(var(--base) * 3.625);
    transform: translate(0%, 0);
    transform: translate(0, -50%);
  }
}

.archive--filter-container {
  position: fixed;
  z-index: 200;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  top: calc(var(--app-height) * 0.2203898051 + calc(var(--base) * 2));
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.1975560081  + calc(var(--base) * 2));
  }
}
.v-main{
  height:100%;
}
</style>
<style lang="scss">
.page-archive--header__subtitle span {
  font-size: calc(var(--base) * 2);
  line-height: calc(var(--base) * 2.5);
}
</style>
