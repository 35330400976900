var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page-user-panel"},[_c('user-panel-layout',{attrs:{"active":"projects"}},[((this.$vuetify.breakpoint.width < 1024 && _vm.isSuperAdmin))?_c('div',{staticClass:"admin-switch--wrapper"},[_c('div',{staticClass:"admin-switch user-panel--button",style:({
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
        })},[_c('button',{staticClass:"admin-switch__button",class:[!_vm.isAdmin ? 'selected' : '']},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.yourProjects"))+" ")]),_vm._v(" / "),_c('button',{staticClass:"admin-switch__button",class:[_vm.isAdmin ? 'selected' : ''],on:{"click":function($event){return _vm.goToAdminProjects()}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.administrative"))+" ")])])]):_vm._e(),_c('section',{staticClass:"panel-module panel--your-projects",style:({
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
      })},[_c('div',{staticClass:"panel-module--header"},[_c('h4',{staticClass:"panel-module--header__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.yourProjects"))+" ")]),_c('div',{staticClass:"panel-module--header__buttons"},[(this.$vuetify.breakpoint.width >= 1024 && _vm.isSuperAdmin)?_c('div',{staticClass:"admin-switch user-panel--button",style:({
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
            })},[_c('button',{staticClass:"admin-switch__button",class:[!_vm.isAdmin ? 'selected' : '']},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.yourProjects"))+" ")]),_vm._v(" / "),_c('button',{staticClass:"admin-switch__button",class:[_vm.isAdmin ? 'selected' : ''],on:{"click":function($event){return _vm.goToAdminProjects()}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.administrative"))+" ")])]):_vm._e()])]),_c('div',{staticClass:"panel-module--body",class:[_vm.loading ? 'overflow-hidden' : ''],style:({
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
        })},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light}}):_vm._e(),_c('div',{staticClass:"project-type-selector"},[(_vm.isCreator || _vm.isSuperAdmin)?_c('button',{staticClass:"project-type-selector__button",class:[!_vm.showFavorites ? 'selected' : ''],on:{"click":function($event){_vm.showFavorites = false}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.created"))+" ")]):_vm._e(),(_vm.isSuperAdmin)?_c('div',[_vm._v("|")]):_vm._e(),(_vm.isInstitution || _vm.isSuperAdmin)?_c('button',{staticClass:"project-type-selector__button",class:[_vm.showFavorites || _vm.isInstitution ? 'selected' : ''],on:{"click":function($event){_vm.showFavorites = true}}},[_vm._v(" "+_vm._s(_vm.$t("message.admin.saved"))+" ")]):_vm._e()]),(_vm.isCreator || _vm.isSuperAdmin)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showFavorites),expression:"!showFavorites"}],ref:"swiper1",staticClass:"project-swiper swiper"},[_c('div',{staticClass:"swiper-wrapper"},[(this.$vuetify.breakpoint.width >= 1024)?_vm._l((_vm.myProjects),function(project){return _c('project-card',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                    ? _vm.$getProjectCategory(project.type)
                    : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.chatRequests.length,"likes":project.projectFavorites.length,"views":project.views,"adminView":true,"published":project.published}})}):_vm._l((_vm.myProjects),function(project){return _c('project-card-movil',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                    ? _vm.$getProjectCategory(project.type)
                    : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.chatRequests.length,"likes":project.projectFavorites.length,"views":project.views,"adminView":true,"published":project.published}})})],2),_c('div',{staticClass:"swiper-navigation-wrapper"},[_c('div',{staticClass:"swiper-button-custom-prev swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-left.svg"),"alt":""}})]),_c('div',{staticClass:"swiper-button-custom-next swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-right.svg"),"alt":""}})])])]):_vm._e(),(_vm.isInstitution || _vm.isSuperAdmin)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFavorites || _vm.isInstitution),expression:"showFavorites || isInstitution"}],ref:"swiperFavorites",staticClass:"project-swiper swiper"},[_c('div',{staticClass:"swiper-wrapper"},[(this.$vuetify.breakpoint.width >= 1024)?_vm._l((_vm.savedProjects),function(project){return _c('project-card',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                    ? _vm.$getProjectCategory(project.type)
                    : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.chatRequests.length,"likes":project.projectFavorites.length,"views":project.views}})}):_vm._l((_vm.savedProjects),function(project){return _c('project-card-movil',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                    ? _vm.$getProjectCategory(project.type)
                    : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.chatRequests.length,"likes":project.projectFavorites.length,"views":project.views}})})],2),_c('div',{staticClass:"swiper-navigation-wrapper"},[_c('div',{staticClass:"swiper-fav-button-custom-prev swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-left.svg"),"alt":""}})]),_c('div',{staticClass:"swiper-fav-button-custom-next swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-right.svg"),"alt":""}})])])]):_vm._e()],1)]),_c('section',{staticClass:"panel-module panel--recommended-projects",style:({
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
      })},[_c('div',{staticClass:"panel-module--header"},[_c('h4',{staticClass:"panel-module--header__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.projectRecommendation"))+" ")]),_c('div',{staticClass:"panel-module--header__buttons"})]),_c('div',{staticClass:"panel-module--body",class:[_vm.loading ? 'overflow-hidden' : ''],style:({
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
        })},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light}}):_vm._e(),_c('div',{ref:"swiper2",staticClass:"project-swiper swiper"},[_c('div',{staticClass:"swiper-wrapper"},[(this.$vuetify.breakpoint.width >= 1024)?_vm._l((_vm.recommendedProjects),function(project){return _c('project-card',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                    ? _vm.$getProjectCategory(project.type)
                    : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.starsNumber,"likes":project.favoritesNumber,"views":project.views}})}):_vm._l((_vm.recommendedProjects),function(project){return _c('project-card-movil',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                    ? _vm.$getProjectCategory(project.type)
                    : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.starsNumber,"likes":project.favoritesNumber,"views":project.views}})})],2),_c('div',{staticClass:"swiper-navigation-wrapper"},[_c('div',{staticClass:"swiper-recomm-button-custom-prev swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-left.svg"),"alt":""}})]),_c('div',{staticClass:"swiper-recomm-button-custom-next swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-right.svg"),"alt":""}})])])])],1)]),_c('section',{staticClass:"panel-module panel--recent-projects",style:({
        backgroundColor:
          'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
      })},[_c('div',{staticClass:"panel-module--header"},[_c('h4',{staticClass:"panel-module--header__title"},[_vm._v(" "+_vm._s(_vm.$t("message.admin.panels.lastIncorporation"))+" ")]),_c('div',{staticClass:"panel-module--header__buttons"})]),_c('div',{staticClass:"panel-module--body",class:[_vm.loading ? 'overflow-hidden' : ''],style:({
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
        })},[(_vm.loading)?_c('panel-module-loader',{attrs:{"color":this.$getUserColor(this.selectedColor).dark,"bgColor":this.$getUserColor(this.selectedColor).light,"borderRadius":"8"}}):_vm._e(),_c('div',{ref:"swiper3",staticClass:"project-swiper swiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.lastProjects),function(project){return _c('project-card',{key:project.id,staticClass:"swiper-slide",attrs:{"title":project.titleCastellano,"titleCat":project.titleValencia,"author":project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia,"projectId":project.id,"authorCat":project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano,"cat":_vm.$getProjectCategory(project.type)
                  ? _vm.$getProjectCategory(project.type)
                  : _vm.$getProjectCategory('SOCIAL'),"img":project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0],"stars":project.starsNumber,"likes":project.favoritesNumber,"views":project.views}})}),1),_c('div',{staticClass:"swiper-navigation-wrapper"},[_c('div',{staticClass:"swiper-button-custom-prev swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-left.svg"),"alt":""}})]),_c('div',{staticClass:"swiper-button-custom-next swiper-button"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-chevron-circle-right.svg"),"alt":""}})])])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }