<template>
  <div class="page page-user-panel">
    <user-panel-layout active="messages">
      <!-- Inicio de Mesagge users -->
      <div
        class="admin-switch--wrapper"
        v-if="this.$vuetify.breakpoint.width < 1024 && this.isSuperAdmin"
      >
        <div
          class="admin-switch user-panel--button"
          :style="{
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          }"
        >
          <button
            class="admin-switch__button"
            :class="[!isAdmin ? 'selected' : '']"
            @click="isAdmin = false"
          >
            {{ $t("message.admin.panels.yourMessages") }}
          </button>
          /
          <button
            class="admin-switch__button"
            :class="[isAdmin ? 'selected' : '']"
            @click="isAdmin = true"
          >
            {{ $t("message.admin.administrative") }}
          </button>
        </div>
      </div>
      <section
        class="panel-module panel--messages--users"
        :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
        }"
        :class="[isSuperAdmin ? 'short' : 'long']"
      >
        <div class="panel-module--header">
          <div class="panel-module--header--inner">
            <h4 class="panel-module--header__title">
              {{ $t("message.admin.panels.messages") }}
            </h4>

            <div class="panel-module--header__buttons">
              <a
                href="javascript:;"
                class="new-conversation--button"
                @click="newConversation()"
              >
                <img src="@/assets/icons/icon-new-conversation.svg" alt="" />
                {{ $t("message.admin.newConversation") }}
              </a>
            </div>
          </div>
          <div class="messages--search-input">
            <v-text-field
              hide-details
              rounded
              filled
              dense
              prepend-inner-icon="mdi-magnify"
              background-color="#ffffff"
              :placeholder="this.$t('message.admin.searchInMessages')"
            ></v-text-field>
          </div>
        </div>
        <div
          class="panel-module--body"
          :class="[loading ? 'overflow-hidden' : '']"
        >
          <panel-module-loader
            :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light"
            v-if="loading"
          ></panel-module-loader>
          <div class="messages--user-list" v-if="isAdmin">
            <user-collapsable-card
              user="Nombre de Usuario"
              avatar=""
              :color="selectedColor"
            >
              <user-card-admin
                @click.native="openConversation()"
                avatar=""
                user="Nombre de Usuario"
                user2="2ndo Nombre de Usuario"
                :read="false"
                :timestamp="1669716278000"
                :color="selectedColor"
                message="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia vel dolorum odit illo quis, magnam voluptates? Aperiam quis aliquam pariatur, quod, fugiat eius inventore a tenetur voluptates accusamus rerum consectetur?"
              ></user-card-admin>
              <user-card-admin
                @click.native="openConversation()"
                avatar=""
                user="Nombre de Usuario"
                user2="2ndo Nombre de Usuario"
                :read="false"
                :timestamp="1669716278000"
                :color="selectedColor"
                message="Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia vel dolorum odit illo quis, magnam voluptates? Aperiam quis aliquam pariatur, quod, fugiat eius inventore a tenetur voluptates accusamus rerum consectetur?"
              ></user-card-admin>
            </user-collapsable-card>
          </div>
          <div class="messages--user-list" v-if="!isAdmin">
            <user-card
              v-for="(item, index) in chats"
              :key="index"
              @click.native="openConversation()"
              avatar=""
              :user="item.name"
              :read="false"
              :timestamp="1669716278000"
              :color="selectedColor"
              :message="item.message"
            ></user-card>
          </div>
        </div>
      </section>
      <!-- Fin de Mesagge users -->
      <section
        class="panel-module panel--messages--conversations long"
        :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
        }"
        :class="[isNewConversation ? 'slide-left' : '']"
        v-show="isNewConversation"
      >
        <div class="panel-module--header">
          <div class="panel-module--header--inner">
            <div class="panel-module--header__buttons">
              <a @click="isNewConversation = false">
                <img src="@/assets/icons/icon-chevron-back.svg" alt="" />
              </a>
            </div>
            <h4 class="panel-module--header__title">
              {{ $t("message.admin.panels.newConversation") }}
            </h4>
          </div>
          <div class="messages--search-input">
            <v-text-field
              hide-details
              rounded
              filled
              dense
              prepend-inner-icon="mdi-magnify"
              background-color="#ffffff"
              :placeholder="this.$t('message.admin.panels.for')"
            ></v-text-field>
          </div>
        </div>
        <div
          class="panel-module--body"
          :class="[loading ? 'overflow-hidden' : '']"
        >
          <panel-module-loader
            :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light"
            v-if="loading"
          ></panel-module-loader>
          <div class="messages--user-list">
            <new-conversation-card
              v-for="(item, index) in chats"
              :key="index"
              @click.native="openConversation()"
              avatar=""
              :user="item.name"
              :color="selectedColor"
              :message="item.message"
            ></new-conversation-card>
          </div>
          <button class="messages--new-conversation--submit">
            {{ $t("message.admin.panels.startChat") }}
          </button>
        </div>
      </section>
      <!-- Fin de Mesagge users -->
      <conversation-panel-layout
        mainUserName="Usuario Nombre 1"
        secondaryUserName="Usuario Nombre 2"
        :isOpen="isConversationOpen"
        ref="conversationPanel"
        @toggleAdmin="toggleAdmin"
      ></conversation-panel-layout>
    </user-panel-layout>
  </div>
</template>

<script>
import chatServices from '@/services/chat.services';
import UserPanelLayout from '@/components/Layouts/UserPanelLayout.vue';
import ConversationPanelLayout from '@/components/Layouts/ConversationPanelLayout.vue';

import UserCard from '@/components/Cards/UserCard.vue';
import UserCardAdmin from '@/components/Cards/UserCardAdmin.vue';
import NewConversationCard from '@/components/Cards/NewConversationCard.vue';

import UserCollapsableCard from '@/components/Cards/UserCollapsableCard.vue';
import PanelModuleLoader from '@/components/Loaders/PanelModuleLoader.vue';

export default {
  name: "UserPanel-messages",
  components: {
    UserPanelLayout,
    UserCard,
    UserCardAdmin,
    UserCollapsableCard,
    ConversationPanelLayout,
    PanelModuleLoader,
    NewConversationCard
  },
  data() {
    return {
      active: 'messages',
      isSuperAdmin:false,
      isAdmin: false,
      isOwner: true,
      isConversationOpen: false,
      loading:false,
      isNewConversation: true,
      chats: [],
      //add fake users to test
      users: []
      
      
    };
  },
  mounted() {
    this.$store.dispatch('users/getLoggedUser');
    this.getChats();

  },
  computed: {
    selectedColor() {
      if (this.user && this.user.color) {
        return this.user.color;
      }
      return 'default';
    },
    user() {
      return this.$store.state.users.loggedUser;
    },
  },
  methods: {
    newConversation() {
      this.isNewConversation = true;
    },
    toggleAdmin() {
      this.admin = !this.admin;
    },
    openConversation() {
      this.$refs.conversationPanel.openConversation();
    },
    closeConversation() {
      this.isConversationOpen = false;
    },
    setName(chat) {
      if(chat.fromUser){
        if(chat.fromUser.name === this.user.name){
          return chat.project.creator.name
        } else {
          return chat.fromUser.name
        }
      }else{
        return chat.project.creator.name}
    },
    getChats() {
      this.loading = true;
      chatServices
        .getMyChats()
        .then((response) => {
          this.loading = false;
          //console.log(response);
          //console.log(this.user.name);
          this.chats = response.map ((chat) => {
            return {
              id: chat.id,
              chatComments: chat.chatComments,
              lastChatComment: chat.chatComments[chat.chatComments.length - 1] ,
              fromUser: chat.fromUser,
              project: chat.project,
              name: this.setName(chat),
              message: chat.chatComments.length > 0 ? chat.chatComments[chat.chatComments.length - 1].text : '...',
            }
          });
          //console.log(this.chats);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

  },

};
</script>

<style lang="scss" scoped>
/* Single Panels */

//Global rules
.panel-module {
  border-radius: calc(var(--base) * 2);
  display: flex;
  flex-direction: column;
  padding: calc(var(--base) * 2);
  transition: all ease-out 0.2s;

  .panel-module--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: calc(var(--base) * 0.125);

    // margin-bottom: calc(var(--base) * 2.625);
    &__title {
      font-size: calc(var(--base) * 2.625);
      line-height: calc(var(--base) * 2.875);
      font-weight: 600;
      max-width: 90%;
      margin: 0;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: calc(var(--base) * 1);
      min-height: calc(var(--base) * 4);
    }
  }

  .panel-module--body {
    width: 100%;
    height: 100%;
    // background-color: white;
    // border-radius: calc(var(--base) * 1);
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
    position: relative;
  }
}
.admin-switch--wrapper {
  grid-column: 1 / span 10;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .user-panel--button {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: calc(var(--base) * 0.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 0.5) calc(var(--base) * 1.25);
    height: 100%;
    min-height: calc(var(--base) * 4);
    max-height: calc(var(--base) * 4);
    button {
      display: block;
      width: auto;
      white-space: nowrap;
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 3);
      &.selected {
        font-weight: 600;
      }
    }
  }
}

.panel--messages--users {
  width: 100%;
  transition: min-height linear 0s;
  &.short {
    min-height: calc(
      var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 17
    );
    max-height: calc(
      var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 17
    );
  }
  &.long {
    min-height: calc(
      var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 11
    );
    max-height: calc(
      var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 11
    );
  }
  @media (min-width: 1024px) {
    margin-top: 0;
    grid-column: 1 / span 4;
    grid-row: 1 / span 6;
    min-height: auto;
    max-height: auto;
    &.short,
    &.long {
      min-height: auto;
      max-height: auto;
    }
  }

  .panel-module--header {
    flex-direction: column;
    padding-bottom: calc(var(--base) * 2);
    .panel-module--header--inner {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    &__buttons {
      .new-conversation--button {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        column-gap: calc(var(--base) * 0.5);
        color: rgba(var(--bodyColor), 0.5);
        margin-bottom: calc(var(--base) * 1);
      }
    }
  }

  .panel-module--body {
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    border-radius: calc(var(--base) * 1);

    &::-webkit-scrollbar {
      display: none;
    }

    .messages--user-list {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 1);
    }
  }
}

.panel--messages--conversations {
  width: calc(100% - var(--base) * 4);
  transition: min-height linear 0s;
  z-index: 10;
  position: absolute;
  right: 0;
  top: calc(var(--app-height) * 0.1304347826);
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  &.slide-left {
    right: calc(var(--base) * 2);
    transform: translateX(0);
  }
  &.long {
    min-height: calc(
      var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 11
    );
    max-height: calc(
      var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 11
    );
  }
  @media (min-width: 1024px) {
    position:static;
    left:auto;
    top:auto;
    right:auto;
    margin-top: 0;
    width:100%;
    grid-column: 1 / span 4;
    grid-row: 1 / span 6;
    transform: translateX(0%);
    min-height: auto;
    max-height: auto;
    &.short,
    &.long {
      position:static;
      min-height: auto;
      max-height: auto;
    }
  }

  .panel-module--header {
    flex-direction: column;
    padding-bottom: calc(var(--base) * 2);
    .panel-module--header--inner {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: calc(var(--base) * 2);
    }
    &__buttons {
      .new-conversation--button {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        column-gap: calc(var(--base) * 0.5);
        color: rgba(var(--bodyColor), 0.5);
        margin-bottom: calc(var(--base) * 1);
      }
    }
  }

  .panel-module--body {
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    border-radius: calc(var(--base) * 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // min-height:100%;
    height:100%;
    position:relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .messages--user-list {
      height: auto;
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 1);
    }
    .messages--new-conversation--submit{
      width:100%;
      max-width:calc(100% - var(--base)*4);
      background-color: rgba(var(--bodyColor), 1);
      color:rgba(var(--bodyBg), 1);
      border-radius:calc(var(--base)*1.25);
      display: flex;
      justify-content: center;
      align-items: center;
      height:calc(var(--base)*5);
      margin-top:auto;
      position:fixed;
      bottom:calc(var(--base)*2);
      left:50%;
      transform:translateX(-50%);
    }
  }
}
</style>
<style lang="scss">
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  border-radius: calc(var(--base) * 12);
}
</style>