<template>
  <div class="page page-user-panel">
    <user-panel-layout active="projects">
      <div
        class="admin-switch--wrapper"
        v-if="(this.$vuetify.breakpoint.width < 1024 && isSuperAdmin)"
      >
        <div
          class="admin-switch user-panel--button"
          :style="{
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          }"
        >
          <button
            class="admin-switch__button"
            :class="[!isAdmin ? 'selected' : '']"
            @click="goToUserProjects()"
          >
            {{ $t("message.admin.panels.yourMessages") }}
          </button>
          /
          <button
            class="admin-switch__button"
            :class="[isAdmin ? 'selected' : '']"
          >
            {{ $t("message.admin.administrative") }}
          </button>
        </div>
      </div>
      <!-- Inicio de Tus proyectos -->
      <section
        class="panel-module panel--your-projects"
        :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
        }"
      >
        <div class="panel-module--header">
          <h4 class="panel-module--header__title">
            {{ $t("message.admin.administrative") }}
          </h4>
          <div class="panel-module--header__buttons">
            <div
              class="admin-switch user-panel--button"
              :style="{
                backgroundColor:
                  'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
              }"
              v-if="this.$vuetify.breakpoint.width >= 1024 && isSuperAdmin"
            >
              <button
                class="admin-switch__button"
                :class="[!isAdmin ? 'selected' : '']"
                @click="goToUserProjects()"
              >
                {{ $t("message.admin.panels.yourProjects") }}
              </button>
              /
              <button
                class="admin-switch__button"
                :class="[isAdmin ? 'selected' : '']"
              >
                {{ $t("message.admin.administrative") }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="panel-module--body"
          :style="{
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          }"
          :class="[loading ? 'overflow-hidden' : '']"
        >
          <panel-module-loader
            :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light"
            v-if="loading"
          ></panel-module-loader>
          <div class="project-type-selector">
            <button
              class="project-type-selector__button"
              :class="[!showFavorites ? 'selected' : '']"
              @click="showFavorites = false"
            >
            {{ $t("message.admin.panels.approvedProjects") }}
            </button>
          </div>
          <!-- Created projects -->
          <div
            ref="swiper1"
            class="project-swiper swiper"
            v-show="!showFavorites"
          >
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <template v-if="this.$vuetify.breakpoint.width >= 1024">
                <!-- Slides -->
                <project-card
                  v-for="project in approvedProjects"
                  :key="project.id"
                  :title="project.titleCastellano"
                  :titleCat="project.titleValencia"
                  :author="project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia"
                  :projectId="project.id"
                  :authorCat="project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano"
                  :cat="
                    $getProjectCategory(project.type)
                      ? $getProjectCategory(project.type)
                      : $getProjectCategory('SOCIAL')
                  "
                  :img="project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0]"
                  :stars="project.chatRequests.length"
                  :likes="project.projectFavorites.length"
                  :views="project.views"
                  class="swiper-slide"
                  :adminView="true"
                  :published="project.published"
                ></project-card>
              </template>
              <template v-else>
                <project-card-movil
                  v-for="project in approvedProjects"
                  :key="project.id"
                  :title="project.titleCastellano"
                  :titleCat="project.titleValencia"
                  :author="project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia"
                  :projectId="project.id"
                  :authorCat="project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano"
                  :cat="
                    $getProjectCategory(project.type)
                      ? $getProjectCategory(project.type)
                      : $getProjectCategory('SOCIAL')
                  "
                  :img="project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0]"
                  :stars="project.chatRequests.length"
                  :likes="project.projectFavorites.length"
                  :views="project.views"
                  class="swiper-slide"
                  :adminView="true"
                  :published="project.published"
                ></project-card-movil>
              </template>
            </div>
            <div class="swiper-navigation-wrapper">
              <div class="swiper-button-custom-prev swiper-button">
                <img src="@/assets/icons/icon-chevron-circle-left.svg" alt="" />
              </div>
              <div class="swiper-button-custom-next swiper-button">
                <img
                  src="@/assets/icons/icon-chevron-circle-right.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Final de Tus proyectos -->
      <!-- Inicio de proyectos recomendados -->
      <section
        class="panel-module panel--your-projects"
        :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
        }"
      >
        <div class="panel-module--header">
          <h4 class="panel-module--header__title">
            {{ $t("message.admin.panels.pendingProjects") }}
          </h4>
          <div class="panel-module--header__buttons"></div>
        </div>
        <div
          class="panel-module--body"
          :style="{
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
          }"
          :class="[loading ? 'overflow-hidden' : '']"
        >
          <panel-module-loader
            :color="this.$getUserColor(this.selectedColor).dark"
            :bgColor="this.$getUserColor(this.selectedColor).light"
            v-if="loading"
          ></panel-module-loader>
          <div ref="swiper2" class="project-swiper swiper">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <template v-if="this.$vuetify.breakpoint.width >= 1024">
                <!-- Slides -->
                <project-card
                  v-for="project in pendingProjects"
                  :key="project.id"
                  :title="project.titleCastellano"
                  :titleCat="project.titleValencia"
                  :author="project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia"
                  :projectId="project.id"
                  :authorCat="project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano"
                  :cat="
                    $getProjectCategory(project.type)
                      ? $getProjectCategory(project.type)
                      : $getProjectCategory('SOCIAL')
                  "
                  :img="project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0]"
                  :stars="project.chatRequests.length"
                  :likes="project.projectFavorites.length"
                  :views="project.views"
                  class="swiper-slide"
                  :adminView="true"
                  :published="project.published"
                ></project-card>
              </template>
              <template v-else>
                <project-card-movil
                  v-for="project in pendingProjects"
                  :key="project.id"
                  :title="project.titleCastellano"
                  :titleCat="project.titleValencia"
                  :author="project.authors[0].nameCastellano ? project.authors[0].nameCastellano : project.authors[0].nameValencia"
                  :projectId="project.id"
                  :authorCat="project.authors[0].nameValencia ? project.authors[0].nameValencia : project.authors[0].nameCastellano"
                  :cat="
                    $getProjectCategory(project.type)
                      ? $getProjectCategory(project.type)
                      : $getProjectCategory('SOCIAL')
                  "
                  :img="project.projectMainImages[0] ? project.projectMainImages[0].imageUrl: project.projectMainImages[0]"
                  :stars="project.chatRequests.length"
                  :likes="project.projectFavorites.length"
                  :views="project.views"
                  class="swiper-slide"
                  :adminView="true"
                  :published="project.published"
                ></project-card-movil>
              </template>
            </div>

            <!-- If we need navigation buttons -->
            <div class="swiper-navigation-wrapper">
              <div class="swiper-button-custom-prev swiper-button">
                <img src="@/assets/icons/icon-chevron-circle-left.svg" alt="" />
              </div>
              <div class="swiper-button-custom-next swiper-button">
                <img
                  src="@/assets/icons/icon-chevron-circle-right.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Final de Proyectos Recomendados -->
    </user-panel-layout>
  </div>
</template>

<script>
import ProjectService from '@/services/project.services';
import UserPanelLayout from '@/components/Layouts/UserPanelLayout.vue';
import Swiper, { Navigation, Pagination } from 'swiper';
import ProjectCard from '@/components/Cards/ProjectCard.vue';
import ProjectCardMovil from '@/components/Cards/ProjectCardMovil.vue';
import PanelModuleLoader from '@/components/Loaders/PanelModuleLoader.vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
  name: "UserPanel-projects",
  components: {
    UserPanelLayout,
    ProjectCard,
    ProjectCardMovil,
    PanelModuleLoader,
  },
  data() {
    return {
      active: 'projects',
      isSuperAdmin: this.$store.getters['auth/hasRole'](['ROLE_ADMIN']),
      isCreator: this.$store.getters['auth/hasRole'](['ROLE_CREATOR']),
      isInstitution: this.$store.getters['auth/hasRole'](['ROLE_INSTITUTION']),
      isAdmin: true,
      isOwner: true,
      approvedProjects: [],
      pendingProjects: [],
      projects: [],
      // new commands
      showFavorites: false,
      loading: true,
    };
  },
  mounted() {
    this.getAllProjects();
  },
  computed: {
    selectedColor() {
      if (this.user && this.user.color) { 
        return this.user.color;
      }
      return 'default';
    },
    user() {
      return this.$store.state.users.loggedUser;
    },
  },
  methods: {

    toggleAdmin() {
      this.admin = !this.admin;
    },
    goToUserProjects() {
      this.$router.push({ name: 'UserPanelProyectos' });
    },
    returnProjectsByPublished(val) {
      return this.projects.filter((project) => project.published === val);
    },
    getAllProjects() {
      ProjectService.getAllProjects(50)
        .then((response) => {
          this.projects = response.content;
          this.approvedProjects = this.returnProjectsByPublished(true);
          this.pendingProjects = this.returnProjectsByPublished(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.initSwiper1();
          this.initSwiper2();
        });
    },

    setApprovedProjects() {
      this.approvedProjects = this.projects.filter((project) => project.status === 'APPROVED');
      this.initSwiper1();
    },

    initSwiper1() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper1, {
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        autoHeight: false,
        modules: [Navigation, Pagination],
        // grabCursor: true,
        slidesPerView: 'auto',
        // centeredSlides: true,
        spaceBetween: 16,
        // initialSlide: 3,
        // loop: true,

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-custom-next',
          prevEl: '.swiper-button-custom-prev',
        },
      });
    },
    initSwiper2() {

      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper2, {
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        autoHeight: false,
        modules: [Navigation, Pagination],
        // grabCursor: true,
        slidesPerView: 'auto',
        // centeredSlides: true,
        spaceBetween: 16,
        // initialSlide: 3,
        // loop: true,

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-custom-next',
          prevEl: '.swiper-button-custom-prev',
        },
      });
    },
    initSwiper3() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper3, {
        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        autoHeight: false,
        modules: [Navigation, Pagination],
        // grabCursor: true,
        slidesPerView: 'auto',
        // centeredSlides: true,
        spaceBetween: 16,
        // initialSlide: 3,
        // loop: true,

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-custom-next',
          prevEl: '.swiper-button-custom-prev',
        },
      });
    },
    initSwipperFavorites() {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiperFavorites, {
        autoHeight: false,
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
          nextEl: '.swiper-button-custom-next',
          prevEl: '.swiper-button-custom-prev',
        },
      });
    },
  },

};
</script>

<style lang="scss" scoped>
/* Single Panels */

//Global rules
.panel-module {
  border-radius: calc(var(--base) * 2);
  display: flex;
  flex-direction: column;
  padding: calc(var(--base) * 2);
  transition: all ease-out 0.2s;

  .panel-module--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: calc(var(--base) * 0.125);

    // margin-bottom: calc(var(--base) * 2.625);
    &__title {
      font-size: calc(var(--base) * 2.5);
      line-height: calc(var(--base) * 2.75);
      font-weight: 600;
      max-width: 100%;
      margin: 0;

      @media (min-width: 1024px) {
        font-size: calc(var(--base) * 2.625);
        line-height: calc(var(--base) * 2.875);
        max-width: 90%;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: calc(var(--base) * 1);
      min-height: calc(var(--base) * 4);
    }
  }

  .panel-module--body {
    width: 100%;
    height: 100%;
    // background-color: white;
    // border-radius: calc(var(--base) * 1);
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
    overflow-y: scroll;
    position: relative;
  }
}

.admin-switch--wrapper {
  grid-column: 1 / span 10;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .user-panel--button {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: calc(var(--base) * 0.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 0.5) calc(var(--base) * 1.25);
    height: 100%;
    min-height: calc(var(--base) * 4);
    max-height: calc(var(--base) * 4);

    button {
      display: block;
      width: auto;
      white-space: nowrap;
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 3);

      &.selected {
        font-weight: 600;
      }
    }
  }
}

//Tus Proyectos
.panel--your-projects.panel-module {
  width: 100%;
  min-height: calc(var(--app-height) * 0.5);
  height: calc(var(--app-height) * 0.5);
  @media (min-width: 1024px) {
    grid-column: 1 / span 10;
    grid-row: span 4;
    min-height: auto;
    height: auto;
  }

  .user-panel--button {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: calc(var(--base) * 0.5);
    border-radius: calc(var(--base) * 1);
    padding: calc(var(--base) * 0.5) calc(var(--base) * 1.25);
    height: 100%;
    min-height: calc(var(--base) * 4);
    max-height: calc(var(--base) * 4);

    button {
      display: block;
      width: auto;
      white-space: nowrap;
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 3);

      &.selected {
        font-weight: 600;
      }
    }
  }

  .panel-module--body {
    height: 100%;
    display: block;
    gap: 0;
    border-radius: calc(var(--base) * 1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: calc(var(--base) * 2);

    .project-type-selector {
      padding: 0 calc(var(--base) * 2);
      padding-bottom: 0;
      margin-top: calc(var(--base) * 1.25);
      margin-bottom: calc(var(--base) * 2);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: calc(var(--base) * 0.5);
      font-size: calc(var(--base) * 2.25);

      button {
        display: block;
        width: auto;
        white-space: nowrap;
        line-height: calc(var(--base) * 3);

        &.selected {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .swiper {
      width: 100%;
      height: 100%;
      padding: 0 calc(var(--base) * 2);
      position: relative;

      .swiper-slide-active {
        transition: all ease-out 0.2s;
      }

      .project-card {
        height: 100%;
        max-width: auto;
        min-height: calc(var(--app-height) * 0.2);
        max-height: 100%;
        aspect-ratio: auto;
        box-shadow: none;
        border-radius: calc(var(--base) * 2);
        width: calc(100% / 1.5);

        @media (min-width: 1024px) {
          width: calc(100% / 4.7);
        }
      }

      .swiper-navigation-wrapper {
        padding: 0 calc(var(--base) * 2);
        // width: 100%;
        position: absolute;
        z-index: 10;
        top: calc(var(--base) * 1.375);
        left: calc(var(--base) * 1.375);

        display: flex;
        justify-content: space-between;
        gap: calc(var(--base) * 1);
        pointer-events: none;

        .swiper-button {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
  }
}

//Proyectos recomendados
.panel--recommended-projects {
  width: 100%;
  min-height: calc(var(--app-height) * 0.5);
  height: calc(var(--app-height) * 0.5);
  @media (min-width: 1024px) {
    grid-column: 1 / span 5;
    grid-row: span 4;
    min-height: auto;
    height: auto;
  }

  & .panel-module--body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-radius: calc(var(--base) * 1);
    padding-bottom: calc(var(--base) * 2);

    & .swiper {
      padding: 0 calc(var(--base) * 2);
      padding-top: calc(var(--base) * 2);
      position: relative;
      width: 100%;
      height: 100%;

      .swiper-slide-active {
        transition: all ease-out 0.2s;
      }

      .project-card {
        max-width: auto;
        min-height: calc(var(--app-height) * 0.15);
        max-height: 100%;
        height: 100%;
        aspect-ratio: auto;
        box-shadow: none;
        border-radius: calc(var(--base) * 2);
        width: calc(100% / 1.2);

        @media (min-width: 1024px) {
          width: calc(100% / 2);
        }
      }

      .swiper-navigation-wrapper {
        padding: 0 calc(var(--base) * 2);
        // width: 100%;
        position: absolute;
        z-index: 10;
        top: calc(var(--base) * 3.375);
        left: calc(var(--base) * 1.375);

        display: flex;
        justify-content: space-between;
        gap: calc(var(--base) * 1);
        pointer-events: none;

        .swiper-button {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
  }
}

//Proyectos recientes
.panel--recent-projects {
  width: 100%;
  min-height: calc(var(--app-height) * 0.5);
  height: calc(var(--app-height) * 0.5);

  @media (min-width: 1024px) {
    grid-column: 6 / span 5;
    grid-row: span 4;
    min-height: auto;
    height: auto;
  }

  & .panel-module--body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-radius: calc(var(--base) * 1);
    padding-bottom: calc(var(--base) * 2);

    & .swiper {
      padding: 0 calc(var(--base) * 2);
      padding-top: calc(var(--base) * 2);
      position: relative;
      width: 100%;
      height: 100%;

      .swiper-slide-active {
        transition: all ease-out 0.2s;
      }

      .project-card {
        max-width: auto;
        min-height: calc(var(--app-height) * 0.15);
        max-height: 100%;
        height: 100%;
        aspect-ratio: auto;
        box-shadow: none;
        border-radius: calc(var(--base) * 2);
        width: calc(100% / 1.5);

        @media (min-width: 1024px) {
          width: calc(100% / 2.2);
        }
      }

      .swiper-navigation-wrapper {
        padding: 0 calc(var(--base) * 2);
        // width: 100%;
        position: absolute;
        z-index: 10;
        top: calc(var(--base) * 3.375);
        left: calc(var(--base) * 1.375);

        display: flex;
        justify-content: space-between;
        gap: calc(var(--base) * 1);
        pointer-events: none;

        .swiper-button {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
  }
}
</style>