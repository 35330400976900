<template>
  <section
    class="panel-module--conversation"
    :class="[
      isOpen && this.$vuetify.breakpoint.width < 1024 ? 'slide-left' : '',
    ]"
    :style="this.cssVars"
  >
    <div
      class="admin-switch--wrapper"
      v-if="this.$vuetify.breakpoint.width >= 1024 && this.isSuperAdmin"
    >
      <div
        class="admin-switch user-panel--button"
        :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
        }"
      >
        <button
          class="admin-switch__button"
          :class="[!isAdmin ? 'selected' : '']"
          @click="toggleAdmin()"
        >
          {{ $t("message.admin.panels.yourMessages") }}
        </button>
        /
        <button
          class="admin-switch__button"
          :class="[isAdmin ? 'selected' : '']"
          @click="toggleAdmin()"
        >
          {{ $t("message.admin.administrative") }}
        </button>
      </div>
    </div>
    <div class="panel-module--conversation--inner" :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
            }">
      <panel-module-loader
        :color="this.$getUserColor(this.selectedColor).dark"
        :bgColor="this.$getUserColor(this.selectedColor).light"
        v-if="loading"
        borderRadius="16"
      ></panel-module-loader>
      <div class="conversation--header user-panel--header">
        <a
          href="#"
          class="conversation--header--back-button"
          v-if="this.$vuetify.breakpoint.width < 1024"
          @click="closeConversation()"
        >
          <img src="@/assets/icons/icon-chevron-back.svg" alt="back" />
        </a>
        <div class="conversation--header--avatars" v-if="isAdmin">
          <div
            class="conversation--header--avatars__item"
            :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).dark + ')',
            }"
          >
            <img :src="mainUserAvatar" alt="" v-if="mainUserAvatar" />
            <span v-else>{{ getInitials(mainUserName) }}</span>
          </div>
          <div
            class="conversation--header--avatars__item"
            :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).dark + ')',
            }"
          >
            <img :src="secondaryUserAvatar" alt="" v-if="secondaryUserAvatar" />
            <span v-else>{{ getInitials(secondaryUserName) }}</span>
          </div>
        </div>
        <div
          class="conversation--header--avatar"
          :style="{
            backgroundColor:
              'rgb(' + this.$getUserColor(this.selectedColor).dark + ')',
          }"
          v-else
        >
          <img :src="mainUserAvatar" alt="" v-if="mainUserAvatar" />
          <span v-else>{{ getInitials(mainUserName) }}</span>
        </div>
        <div class="conversation--header--title" v-if="isAdmin">
          {{ mainUserName }} - {{ secondaryUserName }}
        </div>
        <div class="conversation--header--title" v-else>
          {{ mainUserName }}
        </div>
      </div>
      <div
        class="conversation--content user-panel--content"
        :style="{
          backgroundColor:
            'rgb(' + this.$getUserColor(this.selectedColor).medium + ')',
        }"
      >
        <div class="conversation--content--inner">
          <div
            class="conversation--content--inner__messages"
            :style="{
              backgroundColor:
                'rgb(' + this.$getUserColor(this.selectedColor).light + ')',
            }"
          >
            <div
              class="conversation--content--inner__messages__item"
              v-for="(message, index) in messages"
              :key="index"
              :class="[
                message.user == mainUserId ? 'main-user' : 'secondary-user',
              ]"
            >
              <div class="conversation--content--inner__messages__item__text">
                {{ message.message }}
              </div>
              <div
                class="conversation--content--inner__messages__item__timestamp"
              >
                {{ timeAgo(message.timestamp) }}
              </div>
            </div>
          </div>
          <div class="conversation--content--inner__input">
            <v-text-field
              v-model="message"
              :placeholder="$t('message.admin.writeAMessage')"
              :label="$t('message.admin.writeAMessage')"
              solo
              rounded
              flat
              :dense="true"
              :hide-details="true"
              :single-line="true"
              :clearable="true"
              @click:append="sendMessage()"
            ></v-text-field>
            <button class="message--submit-btn">
              {{ $t("message.contact.send") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PanelModuleLoader from '@/components/Loaders/PanelModuleLoader.vue';
export default {
  props: {
    mainUserName: {
      type: String,
      required: true,
    },
    mainUserAvatar: {
      type: String,
      required: false,
    },
    secondaryUserName: {
      type: String,
      required: true,
    },
    secondaryUserAvatar: {
      type: String,
      required: false,
    },
  },
  components: {
    PanelModuleLoader,
  },
  computed: {
    selectedColor() {
      if (this.user && this.user.color) {
        return this.user.color;
      }
      return 'default';
    },
    user() {
      return this.$store.state.users.loggedUser;
    },
    cssVars() {
      return {
        '--color-head': this.$getUserColor(this.selectedColor).dark,
        '--color-track':
          this.$getUserColor(this.selectedColor).light,
      };
    },
  },
  methods: {
   
    toggleAdmin() {
      this.isAdmin = !this.isAdmin
      this.$emit('toggleAdmin')
    },
    openConversation() {
      this.isOpen = true
    },
    closeConversation() {
      this.isOpen = false
    },
    getInitials(name) {
      let initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    timeAgo(timestamp) {
      let date = new Date(timestamp);
      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.years');
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.months');
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.days');
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.hours');
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.minutes');
      }
      return this.$t('message.admin.notifications.timestamp.prepend') + " " + interval + " " + this.$t('message.admin.notifications.timestamp.seconds');
    },
  },
  data() {
    return {
      loading: true,
      isSuperAdmin: false,
      isAdmin: true,
      isOpen: false,
      mainUserId: 1,
      secondaryUserId: 2,
      message: '',
      messages: [
        {
          user: 1,
          message: "Hola, ¿cómo estás?",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Hola, bien y tú?",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Bien, gracias",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Me alegro",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Hola, ¿cómo estás?",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Hola, bien y tú?",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Bien, gracias",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Me alegro",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Hola, ¿cómo estás?",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Hola, bien y tú?",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Bien, gracias",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Me alegro",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Hola, ¿cómo estás?",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Hola, bien y tú?",
          timestamp: 1620000000000,
        },
        {
          user: 1,
          message: "Bien, gracias",
          timestamp: 1620000000000,
        },
        {
          user: 2,
          message: "Me alegro",
          timestamp: 1620000000000,
        },
      ],
    };
  },
}
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px red;
// }
.panel-module--conversation {
  &.slide-left {
    right: calc(var(--base) * 2);
    transform: translateX(0);
  }

  width: calc(100% - var(--base) * 4);
  height: 100%;
  min-height: calc(
    var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 11
  );
  max-height: calc(
    var(--app-height) - var(--app-height) * 0.1304347826 - var(--base) * 11
  );
  position: absolute;
  right: 0;
  top: calc(var(--app-height) * 0.1304347826);
  z-index: 10;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    grid-column: 5 / span 6;
    grid-row: 1 / span 6;
    position: static;
    height: auto;
    max-height: auto;
    min-height: auto;
    transform: translateX(0%);
    width: 100%;

    .admin-switch--wrapper {
      grid-column: 1 / span 10;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: calc(var(--base) * 1);

      .user-panel--button {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: calc(var(--base) * 0.5);
        border-radius: calc(var(--base) * 1);
        padding: calc(var(--base) * 0.5) calc(var(--base) * 1.25);
        height: 100%;
        min-height: calc(var(--base) * 4);
        max-height: calc(var(--base) * 4);

        button {
          display: block;
          width: auto;
          white-space: nowrap;
          font-size: calc(var(--base) * 1.75);
          line-height: calc(var(--base) * 3);

          &.selected {
            font-weight: 600;
          }
        }
      }
    }
  }

  .panel-module--conversation--inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: calc(var(--base) * 2);
    border-radius: calc(var(--base) * 2);
    gap: calc(var(--base) * 1);
    position: relative;

    .conversation--header {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: calc(var(--base) * 0.125);
      gap: calc(var(--base) * 2);

      .conversation--header--avatars {
        display: flex;
        flex-wrap: nowrap;

        &__item {
          display: flex;
          flex-shrink: 1;
          justify-content: center;
          align-items: center;
          width: calc(var(--base) * 4.875);
          height: calc(var(--base) * 4.875);
          max-width: calc(var(--base) * 4.875);
          max-height: calc(var(--base) * 4.875);
          min-width: calc(var(--base) * 4.875);
          min-height: calc(var(--base) * 4.875);
          border-radius: 50%;

          &:last-of-type {
            margin-left: calc(var(--base) * -1.85);
          }
        }
      }

      .conversation--header--avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(var(--base) * 4.875);
        height: calc(var(--base) * 4.875);
        border-radius: 50%;
      }

      .conversation--header--title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: calc(var(--base) * 2.625);
        line-height: 1;
      }
    }

    .conversation--content {
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .conversation--content--inner {
        display: flex;
        flex-direction: column;
        gap: calc(var(--base) * 2);
        height: 100%;
        &__messages {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: calc(var(--base) * 2.125);
          height: 100%;
          max-height: 100%;
          width: 100%;
          padding: calc(var(--base) * 2);
          overflow-y: scroll;
          overflow-x: hidden;
          scrollbar-width: thin;
          scrollbar-color: rgb(var(--color-head)) rgb(var(--color-track));
          border-radius: calc(var(--base) * 1);
          &::-webkit-scrollbar {
            width: calc(var(--base) * 0.5);
          }
          &::-webkit-scrollbar-track {
            background: rgb(var(--color-track));
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--color-head));
            border-radius: calc(var(--base) * 0.5);
            // border: calc(var(--base) * 0.25) solid rgb(var(--color-track));
          }
          &__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: space-between;
            position: relative;
            padding: calc(var(--base) * 1.625) calc(var(--base) * 2)
              calc(var(--base) * 3.875) calc(var(--base) * 1.125);
            background-color: #fbf9ff;
            min-width: 80%;
            max-width: 90%;
            border-radius: calc(var(--base) * 2) calc(var(--base) * 2)
              calc(var(--base) * 2) calc(var(--base) * 1);
            @media (min-width: 1024px) {
              min-width: 60%;
              max-width: 70%;
            }
            &.main-user {
              align-self: flex-end;
              &:last-of-type {
                margin-bottom: calc(var(--base) * 4);
              }
            }
            &.seconday-user {
              align-self: flex-start;
              &:last-of-type {
                margin-bottom: calc(var(--base) * 4);
              }
            }
            &__text {
              font-size: calc(var(--base) * 2);
              line-height: calc(var(--base) * 3);
              color: rgba(var(--bodyColor), 0.5);
              width: 100%;
            }
            &__timestamp {
              color: rgba(var(--bodyColor), 0.6);
              font-size: calc(var(--base) * 1.75);
              position: absolute;
              bottom: calc(var(--base) * 0.5);
              right: calc(var(--base) * 1);
            }
          }
        }
        &__input {
          display: flex;
          flex-wrap: nowrap;
          gap: calc(var(--base) * 1);
          .message--submit-btn{
            padding:calc(var(--base) * 0.2) calc(var(--base) * 1);
            @media (min-width: 1024px) {
              padding:calc(var(--base) * 0.2) calc(var(--base) * 2);
            }
          }
        }
      }
    }
  }
}
</style>