<template>
  <div class="form-step--wrapper form-upload-edit--step-4">
    <div class="form--content form-upload-edit--step-4--content">
      <form-icon-selector :title="this.$t('message.forms.questions.editAdmin.approveProject')"
        icon="icon-selector-approve.svg" v-on:click.native="setPublished(1)" ref="approveButton"></form-icon-selector>
      <form-icon-selector :title="this.$t('message.forms.questions.editAdmin.leaveOnHold')" icon="icon-selector-deny.svg"
        v-on:click.native="setPublished(0)" ref="denyButton"></form-icon-selector>
    </div>
  </div>
</template>

<script>
import FormIconSelector from "@/components/Forms/Buttons/FormIconSelector.vue";
import projectServices from "@/services/project.services";

export default {
  components: {
    FormIconSelector,
  },
  data() {
    return {
      published: null,
    };
  },
  watch: {
    published: function (newPublished) {
      this.setIconButtons(newPublished);
    },
  },
  methods: {
    setPublished(published) {
      projectServices
        .updatePublished(this.$route.params.id, published)
        .then((response) => {
          console.log("response", response);
        });

      if (published == 1) {

        this.$emit("updateData", { published: true });
      } else {
        this.$emit("updateData", { published: false });
      }

    },

    updateData(data) {
      this.$emit("updateData", data);
    },

    updatePublished(published) {
      this.published = published;
    },

    setIconButtons(published) {
      if (published) {
        this.$refs.approveButton.toggleSelected();
      } else {
        this.$refs.denyButton.toggleSelected();
      }
    },
  },
}

</script>

<style lang="scss" scoped>
.form-step--wrapper {
  // height: 100%;
  // min-height: calc(var(--app-height) * 0.5247376312);
  // max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    // min-height: calc(var(--app-height) * 0.2871690428);
    // max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }

  .form--content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
    max-width: 82.8042328vw;
    margin: 0 auto;
    width: 100%;

    .lang-form-selector {
      display: flex;
      flex-wrap: nowrap;
      column-gap: calc(var(--base) * 0.5);
      margin-bottom: calc(var(--base) * 2);

      a {
        font-size: calc(var(--base) * 2.25);

        &.selected {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .form--grid {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        column-gap: 3.835978836vw;
      }
    }

    .v-input__append-inner {
      .text-field--custom-icon {
        width: calc(var(--base) * 3.5);
        height: calc(var(--base) * 3.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .form--column {
      width: 100%;

      @media (min-width: 1024px) {
        width: 50%;
      }

      .form--row {
        display: flex;
        flex-wrap: nowrap;
        column-gap: calc(var(--base) * 2.25);
        width: 100%;

        .form-input--year {
          width: 100%;
          max-width: 30%;
        }
      }
    }
  }
}
</style>