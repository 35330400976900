<template>
  <div class="page page-map">
    <div class="map-container">
      <div class="map--search-filter">
        <div class="no-results" v-if="projects.length === 0">
          <h2 class="no-results__title" v-text="this.$t('message.archive.noResults')"></h2>
        </div>
        <archive-filter @updateFilter="updateFilter"></archive-filter>
      </div>
      <div class="map--cat-filter">
        <filter-cat-selector-item :cat="$getProjectCategory('SOSTENIBILIDAD')"
          @updateFilter="updateType" :isRouteUp="isRouteUp"></filter-cat-selector-item>
        <filter-cat-selector-item :cat="$getProjectCategory('INSTALACIONES')"
          @updateFilter="updateType" :isRouteUp="isRouteUp"></filter-cat-selector-item>
        <filter-cat-selector-item :cat="$getProjectCategory('ESPACIOS_COMUNES')"
          @updateFilter="updateType" :isRouteUp="isRouteUp"></filter-cat-selector-item>
        <filter-cat-selector-item :cat="$getProjectCategory('SEGURIDAD')"
          @updateFilter="updateType" :isRouteUp="isRouteUp"></filter-cat-selector-item>
        <filter-cat-selector-item :cat="$getProjectCategory('SOCIAL')"
          @updateFilter="updateType" :isRouteUp="isRouteUp"></filter-cat-selector-item>
      </div>
      <template v-if="this.$vuetify.breakpoint.width >= 1024">
        <div class="map--tooltip" v-if="selected">
          <div class="map--tooltip--inner">
            <div class="left-col">
              <img :src="selected.image" alt="" />
            </div>
            <div class="right-col">
              <h4 class="tooltip--title" v-html="
                this.$root.$i18n.locale == 'es'
                  ? this.trimString(selected.titleCastellano, 60)
                  : this.trimString(selected.titleValencia, 60)
              "></h4>
              <div class="tooltip--author-date">
                {{
                    this.$root.$i18n.locale == "es"
                      ? selected.authorCastellano
                      : selected.authorValencia
                }}, {{ selected.year }}
              </div>
              <div class="tooltip--description">
                {{ selected.description }}
                {{
                    this.$root.$i18n.locale == "es"
                      ? this.trimString(selected.descriptionCastellano, 160)
                      : this.trimString(selected.descriptionValencia, 160)
                }}
              </div>
              <router-link class="tooltip--link" :to="{
                name: 'ProjectSingle',
                params: {
                  id: selected.id,
                  slug: this.getSlugFromTitle(selected.titleValencia),
                },
              }">{{ $t("message.map.seeProject") }}
                <span><img src="@/assets/icons/icon-arrow-long-right.svg"
                    alt="" /></span></router-link>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="map--tooltip--mobile" v-if="selected">
          <router-link :to="{
            name: 'ProjectSingle',
            params: {
              id: selected.id,
              slug: this.getSlugFromTitle(selected.titleValencia),
            },
          }" class="map--tooltip--inner">
            <div class="tooltip--image">
              <img :src="selected.image" alt="" />
            </div>
            <h4 class="tooltip--title" v-html="
              this.$root.$i18n.locale == 'es'
                ? this.trimString(selected.titleCastellano, 80)
                : this.trimString(selected.titleValencia, 80)
            "></h4>
            <div class="tooltip--author-date">
              {{
                  this.$root.$i18n.locale == "es"
                    ? selected.authorCastellano
                    : selected.authorValencia
              }}, {{ selected.year }}
            </div>
          </router-link>
        </div>
      </template>
      <div class="map--buttons">
        <a href="javascript:;" class="help-button" @click="openModal()">
          <img src="@/assets/icons/icon-map-question.svg" alt="Help" />
        </a>
        <a href="javascript:;" class="zoom-button zoom-in" @click="$refs.newsMap.zoomIn(1.4)">
          <img src="@/assets/icons/icon-zoom-in.svg" alt="Zoom in" />
        </a>
        <a href="javascript:;" class="zoom-button zoom-out" @click="$refs.newsMap.zoomIn(0.6)">
          <img src="@/assets/icons/icon-zoom-out.svg" alt="Zoom out" />
        </a>
      </div>
      <div class="map--modal" v-if="modal">
        <div class="map--modal--inner">
          <div class="map--modal--header">
            <div class="modal--header--title">
              {{ $t("message.map.modal.helpTitle") }}
            </div>
            <div class="modal--header--close" @click="closeModal">
              <a href="javascript:;">
                <img src="@/assets/icons/icon-close-white.svg" alt="Tancar" />
              </a>
            </div>
          </div>
          <div class="map--modal--body">
            <div class="modal--body--item">
              <div class="modal--body--item__icon">
                <img src="@/assets/icons/icon-map-marker-big.svg" alt="" />
              </div>
              <div class="modal--body--item__text">
                {{ $t("message.map.modal.help1") }}
              </div>
            </div>
            <div class="modal--body--item">
              <div class="modal--body--item__icon">
                <img src="@/assets/icons/icon-map-zoom.svg" alt="" />
              </div>
              <div class="modal--body--item__text">
                {{ $t("message.map.modal.help2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map--wrapper map-box">
        <div :class="mapClass">
          <ProjectsMap ref="newsMap" @updateParent="updateStats"
            @updateChartLoaded="getProjectsFiltered()" @selectedItem="updateTooltip">
          </ProjectsMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectService from '@/services/project.services';
import ProjectsMap from '@/components/Map/StandardMap.vue';
import FilterCatSelectorItem from '@/components/Filters/FilterCatSelectorItem.vue';
import ArchiveFilter from '@/components/Filters/ArchiveFilter.vue';

export default {
  name: 'MapView',
  data() {
    return {
      project: null,
      mapClass: 'map-proj-unselected',

      info: null,
      loading: true,
      errored: false,
      modal: false,

      projectSelectionClass: 'sel-proj-unselected',
      catSelected: null,

      stats: {
        totalValue: 0,
        percentLabeled: 0.0,
      },
      selected: null,
      items: [],

      // Filter
      projects: [],
      filter: {
        year: null,
        status: null,
        title: null,
      },
      filterType: null,
      filterTypeArray: [],
    };
  },
  components: {
    ProjectsMap,
    FilterCatSelectorItem,
    ArchiveFilter,
  },
  mounted() {


  },
  computed: {
    isRouteUp() {
      return !!(this.$route.params.id);
    },
  },
  methods: {
    trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    getSlugFromTitle(title) {
      return title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    },
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    async getAllProjects() {
      const projects = await ProjectService.getAllPublic();
      this.projects = projects.content;
      this.$refs.newsMap.updateData(this.projects);
    },

    async getProjectsFiltered() {
      this.loading = true;
      const projects = await ProjectService.getPublicFiltered(this.filter.title, this.filter.year, this.filter.status, this.filterType);

      //map projects content to projects
      this.projects = projects.content.map((project) => ({
        id: project.id,
        titleValencia: project.titleValencia,
        titleCastellano: project.titleCastellano,
        category: project.category,
        categoryName: this.$getProjectCategory(project.type).name,
        descriptionValencia: project.descriptionShortValencia,
        descriptionCastellano: project.descriptionShortCastellano,
        value: 1,
        color: this.$getProjectCategory(project.type).hexColor,
        year: project.year,
        latitude: project.latitude,
        longitude: project.longitude,
        authorCastellano: project.authors ? project.authors[0].nameCastellano : null,
        authorValencia: project.authors ? project.authors[0].nameValencia : null,
        image: project.projectMainImages[0] ? project.projectMainImages[0].imageUrl : project.projectMainImages[0],
        url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Cat_August_2010-4.jpg/181px-Cat_August_2010-4.jpg',
      }));
      
      if (this.$route.params.id) {
        const id = this.$route.params.id;
        const slug = this.$route.params.slug;
        // get 3 first words from slug and replace - with space
        this.filter.title = slug.split('-').slice(0, 3).join(' ');

        //find project in projects list
        const projectSelected = this.projects.find(project => project.id === id);
        this.selected = projectSelected;
        //empty this.projects
        this.projects = [];
        //add selected project to this.projects
        this.projects.push(projectSelected);
      }
      // this.projects = projects.content;
      this.$refs.newsMap.updateData(this.projects);
      this.loading = false;
    },

    updateType(val) {
      if (this.filterTypeArray.includes(val)) {
        this.filterTypeArray.splice(this.filterTypeArray.indexOf(val), 1);
      } else {
        this.filterTypeArray.push(val);
      }
      // transform array to string
      this.filterType = this.filterTypeArray.join(',');
      this.getProjectsFiltered();
    },

    updateFilter(val) {
      this.filter = val;
      this.getProjectsFiltered();
      this.$refs.newsMap.updateData(this.projects);
    },
    updateStats(val) {
      this.stats.totalValue = val;
    },
    updateTooltip(val) {
      this.selected = val;
    },

  },
};
</script>

<style lang="scss" scoped>
.page-map {
  .map-container {
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    min-height: var(--app-height);
    max-height: var(--app-height);
    position: relative;
    overflow: hidden;

    & .map--search-filter {
      position: absolute;
      top: calc(var(--app-height) * 0.1544227886);
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 110;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;
      gap: calc(var(--base) * 11);

      @media (min-width: 1024px) {
        top: calc(var(--app-height) * 0.1);
        gap: calc(var(--base) * 2);
      }
    }

    & .map--cat-filter {
      position: absolute;
      top: calc(var(--app-height) * 0.215892054);
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      gap: calc(var(--base) * 2);
      z-index: 100;

      @media (min-width: 1024px) {
        flex-direction: column;
        width: auto;
        top: 45%;
        left: 3.373015873vw;
        transform: translate(0, -50%);
      }
    }

    & .map--buttons {
      position: absolute;
      bottom: calc(var(--app-height) * 0.06313645621);
      right: 3.373015873vw;
      display: flex;
      flex-direction: column;
      z-index: 100;

      .help-button {
        width: calc(var(--base) * 4.25);
        height: calc(var(--base) * 4.25);
        background: rgb(var(--bodyBg));
        border: solid 1px rgb(var(--bodyColor));
        border-radius: calc(var(--base) * 0.625);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: calc(var(--base) * 1.875);
        box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
      }

      .zoom-button {
        width: calc(var(--base) * 4.25);
        height: calc(var(--base) * 6.75);
        background: rgb(var(--bodyBg));
        border: solid 1px rgb(var(--bodyColor));
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);

        &.zoom-in {
          border-radius: calc(var(--base) * 0.625) calc(var(--base) * 0.625) 0 0;
        }

        &.zoom-out {
          margin-top: -1px;
          border-radius: 0 0 calc(var(--base) * 0.625) calc(var(--base) * 0.625);
        }
      }
    }

    & .map--tooltip {
      position: fixed;
      bottom: calc(var(--app-height) * 0.04);
      left: 3.373015873vw;
      width: 46.89153439vw;
      min-height: calc(var(--app-height) * 0.2596741344);
      max-height: calc(var(--app-height) * 0.2596741344);
      height: 100%;
      z-index: 200;
      background-color: rgb(var(--bodyBg));
      border: dashed 1px rgb(var(--bodyColor));
      border-radius: calc(var(--base) * 3.125);
      padding: calc(var(--base) * 2.125);
      box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);

      & .map--tooltip--inner {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        gap: calc(var(--base) * 2.875);

        .left-col {
          height: 100%;
          width: 50%;
          border: solid 1px rgb(var(--bodyColor));
          border-radius: calc(var(--base) * 2.5);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .right-col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 50%;

          .tooltip--title {
            font-size: calc(var(--base) * 2.5);
            font-weight: 500;
            color: rgb(var(--bodyColor));
            letter-spacing: 0.01em;
            max-width: 100%;
            min-width: 60%;
            width: auto;
            border-bottom: solid 1px rgb(var(--bodyColor));
            padding-bottom: calc(var(--base) * 0.5);
            margin-bottom: calc(var(--base) * 0.5);
          }

          .tooltip--author-date {
            font-size: calc(var(--base) * 1.625);
            margin-bottom: calc(var(--base) * 1.5);
          }

          .tooltip--description {
            font-size: calc(var(--base) * 1.5);
            line-height: calc(var(--base) * 1.875);
            width: 100%;
            margin-bottom: calc(var(--base) * 2);
          }

          .tooltip--link {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            width: auto;
            gap: calc(var(--base) * 0.5);
            margin-top: auto;
            font-size: calc(var(--base) * 1.75);
            font-weight: 700;
            text-decoration: underline;
            margin-bottom: calc(var(--base) * 2);

            span {
              width: calc(var(--base) * 4);
              height: calc(var(--base) * 0.75);
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    & .map--tooltip--mobile {
      position: absolute;
      bottom: calc(var(--base) * 2);
      left: calc(var(--base) * 2);
      width: 32.53333333vw;
      height: auto;
      // min-height: calc(var(--app-height) * 0.2596741344);
      // max-height: calc(var(--app-height) * 0.2596741344);
      z-index: 200;
      background-color: rgb(var(--bodyBg));
      border: dashed 1px rgb(var(--bodyColor));
      border-radius: calc(var(--base) * 1.25);
      overflow: hidden;

      & .map--tooltip--inner {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: calc(var(--base) * 0.75);
        padding: calc(var(--base) * 0.75);

        & .tooltip--image {
          height: 100%;
          width: 100%;
          max-height: calc(var(--base) * 9);
          border: solid 1px rgb(var(--bodyColor));
          border-radius: calc(var(--base) * 1.25);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        & .tooltip--title {
          font-size: calc(var(--base) * 1.625);
          line-height: calc(var(--base) * 1.75);
          font-weight: 700;
          color: rgb(var(--bodyColor));
          max-width: 100%;
          width: auto;
        }

        & .tooltip--author-date {
          font-size: calc(var(--base) * 1.25);
        }
      }
    }

    & .map--modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 800;
      width: 100vw;
      height: var(--app-height);
      background-color: rgba(var(--bodyColor), 0.37);
      display: flex;
      justify-content: center;
      align-items: center;

      .map--modal--inner {
        width: 30.15873016vw;
        height: auto;
        background: rgb(var(--bodyBg));
        border: solid 1px rgb(var(--bodyColor));
        border-radius: calc(var(--base) * 3.75);
        padding: calc(var(--base) * 3.75) calc(var(--base) * 4.75);
        padding-bottom: calc(var(--base) * 6);
        padding-right: calc(var(--base) * 3.125);

        .map--modal--header {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-bottom: calc(var(--base) * 4);

          & .modal--header--title {
            font-size: calc(var(--base) * 3.5);
            line-height: calc(var(--base) * 3.75);
            font-weight: 700;
            color: rgb(var(--bodyColor));
            text-decoration: underline;
          }

          & .modal--header--close {
            background: rgb(var(--bodyColor));
            width: calc(var(--base) * 3.25);
            height: calc(var(--base) * 3.25);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: calc(var(--base) * 0.625);

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              padding: calc(var(--base) * 0.875);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
        }

        .map--modal--body {
          display: flex;
          flex-direction: column;
          gap: calc(var(--base) * 5.625);
          padding-right: calc(var(--base) * 2);

          .modal--body--item {
            display: flex;
            flex-wrap: nowrap;
            gap: calc(var(--base) * 3.5);

            &__icon {
              width: calc(var(--base) * 6);
              height: auto;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }
          }
        }
      }
    }

    & .map--wrapper {
      min-height: var(--app-height);
      height: 100%;
      height: 100vh;
      width: 100%;
      z-index: 1;

      .standard-map {
        width: 100%;
        min-height: var(--app-height);
        height: 100vh;

        #map-container {
          width: 100%;
          //height: 100%;
          min-height: var(--app-height);
          height: 100vh;
        }
      }
    }
  }
}
</style>